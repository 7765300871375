export const RBAC_TOKEN_KEY = 'RBAC_TOKEN';
export const RBAC_USER_KEY = 'RBAC_USER';
export const RBAC_DANLU_DOMAIN = '.danlu.netease.com';
export const RBAC_FUXI_DOMAIN = '.fuxi.netease.com';

export const AgreementList = [
  {
    url: 'https://static.fuxi.netease.com/zhongbao/app/agreement/用户协议.pdf',
    txt: '《网易有灵使用许可及服务协议》',
  },
  {
    url: 'https://static.fuxi.netease.com/zhongbao/app/agreement/隐私政策.pdf',
    txt: '《网易有灵平台隐私政策》',
  },
  {
    url: 'https://static.fuxi.netease.com/zhongbao/app/agreement/儿童个人信息保护规则及监护人须知.pdf',
    txt: '《网易有灵儿童个人信息保护规则及监护人须知》',
  },
  {
    url: 'https://static.fuxi.netease.com/zhongbao/app/agreement/第三方共享个人信息清单.pdf',
    txt: '《第三方共享个人信息清单》',
  },
];
