import { ReactNode, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import debounce from 'debounce-promise';
import moment from 'moment';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useAntModal } from '@fuxi/eevee-hooks';
import { Button, Checkbox, Flex, Form, Input, message, Modal, Spin, Tooltip } from '@fuxi/eevee-ui';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { getPublishModules, ServiceModuleType } from '@/constants';
import { getServiceVersions } from '@/service/version';
import { StatusDisplay } from '@/components/StatusDisplay';
import { ServiceStatus } from '@/typings/common';
import { PUBLISH_OHTER_PERSIONAL_PROJECT, PUBLISH_PERSIONAL_PROJECT } from '@/service/schema/service/service-mutation';
import { serverStatusMap } from '../constants';
import ProjectDetailContainer from '../ProjectDetailContainer';
import cx from './index.module.less';

interface Prop {
  isServiceCreator: boolean;
  isProjectCreator: boolean;
  projectId: number;
  children: ReactNode;
  serverInfo: {
    id: string;
    modules: ServiceModule[];
  };
}

export const PersonalPublishModal: React.FC<Prop> = props => {
  const { serverInfo, projectId, isServiceCreator } = props;
  const [form] = Form.useForm();
  const [publishing, setPublishing] = useState(false);

  const container = ProjectDetailContainer.useContainer();
  const { getServicesData } = container;

  // const layoutIssiues = useAppSelector(state => state.issue.layoutIssues);

  // const [visible, setVisible] = useState(false);
  // const serverInfo = useAppSelector(state => state.project.currentProject?.se);
  const serviceId = serverInfo?.id;
  const [, setIsDebug] = useState(false);

  const [serviceVersions, setServiceVersions] = useState<ServiceVersion>();
  const [queryServiceVersionsLoading, setQueryServiceVersionsStatus] = useState(false);

  const isTerminalProject = false;

  const [choosedModules, setChoosedModules] = useState<ServiceModuleType[]>([]);

  const [publishPersionalService] = useMutation<{
    publishPersonalService: { serviceId?: string; msg?: string };
  }>(PUBLISH_PERSIONAL_PROJECT);

  const [publishPersonalServiceByOwner] = useMutation<{
    publishPersonalServiceByOwner: { serviceId?: string; msg?: string };
  }>(PUBLISH_OHTER_PERSIONAL_PROJECT);

  // const choosedImageId = useAppSelector(state => state.server.serverInfo?.choosedImageId);

  // useEffect(() => {
  //   emitter.on(ProjectBtnEventType.OpenDeployModal, handleOpen);
  //   emitter.on(ProjectBtnEventType.OpenDebugModal, handleDebugOpen);

  //   return () => {
  //     emitter.off(ProjectBtnEventType.OpenDeployModal, handleOpen);
  //     emitter.off(ProjectBtnEventType.OpenDebugModal, handleDebugOpen);
  //   };
  // }, []);

  const { showModal, visible, closeModal } = useAntModal();

  useEffect(() => {
    setChoosedModules([ServiceModuleType.Cloud]);
  }, [visible]);

  const checkAliasRepeat = (newAlias: string) => {
    const tag = form.getFieldValue('tag');
    const currentVersoin = getVersionByCommmitId(tag);
    const existedVersions = getAllVersions().find(version => version.alias === newAlias);
    if (!!existedVersions && existedVersions?.tag !== currentVersoin?.tag) {
      return true;
    }
    return false;
  };

  const handleOpen = () => {
    if (!visible) {
      showModal();
    }
  };

  useEffect(() => {
    if (!visible) {
      setIsDebug(false);
    } else {
      queryServiceVersions();
      const now = moment(new Date()).format('yyyyMMDDHHmmss');
      form.setFieldValue('alias', now);
    }
  }, [visible]);

  const queryServiceVersions = async () => {
    if (serviceId) {
      setQueryServiceVersionsStatus(true);
      try {
        const res = await getServiceVersions(serviceId);
        setServiceVersions(res?.serviceVersions);
        setQueryServiceVersionsStatus(false);
      } catch (e) {
        setQueryServiceVersionsStatus(false);
      }
    }
  };

  useEffect(() => {
    queryServiceVersions();
  }, [serviceId]);

  const getServerStatusInfo = (status: ServiceStatus) => {
    return serverStatusMap[status];
  };

  const handlePublishModuleChange = (e: CheckboxChangeEvent, type: ServiceModuleType) => {
    const checked = e.target.checked;
    let choosed = [...choosedModules];
    checked ? choosed.push(type) : (choosed = choosed.filter(item => item !== type));
    setChoosedModules(choosed);
  };

  const getServiceModule = (type: string): ServiceModule | undefined => {
    const module: ServiceModule = serverInfo?.modules?.find(module => module.type === type)!;
    return module;
  };

  const getAllVersions = () => {
    const allServices = [
      ...(serviceVersions?.cloudVersions || []),
      ...(serviceVersions?.pageVersions || []),
      ...(serviceVersions?.terminalVersions || []),
    ];
    return allServices;
  };

  const getVersionByCommmitId = (commitId: string) => {
    if (isEmpty(serviceVersions)) {
      return;
    }
    const allServices = getAllVersions();
    const version = allServices.find(item => item.tag === commitId);
    return version;
  };

  const publishModules = Object.keys(getPublishModules(isTerminalProject)).map(type => (
    <div className={cx('module-item')} key={type}>
      <Flex alignStart>
        <Checkbox
          checked={choosedModules.includes(type as ServiceModuleType)}
          onChange={e => handlePublishModuleChange(e, type as ServiceModuleType)}></Checkbox>
        <Flex alignCenter className={cx('module-name')}>
          {getPublishModules(isTerminalProject)?.[type]?.name}
          {getPublishModules(isTerminalProject)?.[type]?.nameTitle && (
            <Tooltip title={getPublishModules(isTerminalProject)?.[type]?.nameTitle}>
              <QuestionCircleOutlined className={cx('name-title')}></QuestionCircleOutlined>
            </Tooltip>
          )}
        </Flex>

        <Flex flexDirection="column">
          <div className={cx('current-version')}>
            {getPublishModules(isTerminalProject)?.[type]?.versionLabel}：
            {getServiceModule(type)?.currentTag ? (
              <Tooltip placement="topLeft" title={getServiceModule(type)?.alias}>
                {getServiceModule(type)?.alias}
              </Tooltip>
            ) : (
              '暂无'
            )}
          </div>

          {getPublishModules(isTerminalProject)?.[type]?.showStatus && getServiceModule(type)?.currentTag && (
            <Flex>
              {/* <div className={cx('placeholder')}></div> */}
              <Flex alignCenter>
                当前运行状态：
                <StatusDisplay statusInfo={getServerStatusInfo(getServiceModule(type)?.status!)}></StatusDisplay>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </div>
  ));

  // const toDeploy = () => {
  //   preparingDeploy();
  //   // 先保存
  //   // dispatch(save({ projectId }))
  //   //   .then(() => {
  //   publishProject();
  //   // })
  //   // .catch(() => deployFinished('失败', LogLevel.Error));
  // };

  const toPublishProject = async () => {
    // emitter.emit(ProjectBtnEventType.Deploy);
    const data = await form.validateFields();
    if (choosedModules?.length === 0) {
      message.error('请至少选择一个发布模块');
      return;
    }

    // 终端项目发布前必须选择自定义镜像
    // if (isTerminalProject && !choosedImageId && choosedModules.includes(ServiceModuleType.Edge)) {
    //   message.error('终端模块发布前必须选择自定义镜像');
    //   return;
    // }

    setPublishing(true);

    if (isServiceCreator) {
      publishPersionalService({
        variables: {
          projectId: projectId,
          alias: data?.alias,
          category: choosedModules,
        },
      })
        .then(res => {
          console.log('res', res);
          // closeModal();
          message.info('正在发起发布流程，请稍后查看发布状态');
        })
        .finally(() => {
          setPublishing(false);
          closeModal();
          getServicesData({ variables: { projectId } });
        });
    } else {
      publishPersonalServiceByOwner({
        variables: {
          projectId: projectId,
          serviceId: serviceId,
          alias: data?.alias,
          category: choosedModules,
        },
      })
        .then(res => {
          console.log('res', res);
          // closeModal();
          message.info('正在发起发布流程，请稍后查看发布状态');
        })
        .finally(() => {
          setPublishing(false);
          closeModal();
          getServicesData({ variables: { projectId } });
        });
    }
  };

  // const hasLayoutIssue = layoutIssiues?.length > 0;

  return (
    <>
      {/* <span onClick={() => setVisible(true)}>{children}</span> */}
      <Modal
        closable={false}
        className={cx('modal-wrapper')}
        title="发布项目"
        destroyOnClose
        // keyboard={!publishing}
        visible={visible}
        bodyStyle={{ paddingBottom: 0, minHeight: 100 }}
        onOk={toPublishProject}
        footer={
          <Flex gap={8} justifyEnd>
            <Button loading={publishing} onClick={closeModal}>
              取消
            </Button>
            <Button loading={publishing} type="primary" onClick={toPublishProject}>
              发布
            </Button>
          </Flex>
        }
        onCancel={closeModal}>
        <Spin spinning={queryServiceVersionsLoading}>
          <Form form={form} preserve={false} labelCol={{ span: 5 }} initialValues={{ serviceId }}>
            <Form.Item
              name="alias"
              required
              rules={[
                {
                  required: true,
                  message: '版本别名不能为空',
                },
                {
                  // lodash的debounce不生效 会延迟校验 因为返回结果不是promise
                  validator: debounce(async (_, value) => {
                    const isRepeat = checkAliasRepeat(value);
                    return !isRepeat ? Promise.resolve() : Promise.reject('别名已存在');
                  }, 50),
                },
              ]}
              label="版本别名">
              <Input autoComplete="off" maxLength={100} showCount></Input>
            </Form.Item>

            <Form.Item className={cx('publish-module-item')} required label="发布模块">
              {publishModules}
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <div
        onClick={() => {
          handleOpen();
        }}>
        {props.children}
      </div>
    </>
  );
};
