import cookie from 'react-cookies';

import { AOP_ASSISTANT_SOURCE } from '@/constants/login';

import { RBAC_TOKEN_KEY, RBAC_USER_KEY } from '../constants';
import { LoginResponse } from '../type';

export const useAccount = () => {
  const rbacTokenCookie = cookie.load(RBAC_TOKEN_KEY);
  const rbacUserCookie = cookie.load(RBAC_USER_KEY);
  const source = new URLSearchParams(window.location.search).get('source');
  let next = new URLSearchParams(window.location.search).get('next');

  const cookieDomain = `.${window.location.hostname.split('.').slice(-3).join('.')}`;
  const loginCb = (rbacInfo: LoginResponse) => {
    const { rbacToken, rbacUser } = rbacInfo;
    cookie.save(RBAC_TOKEN_KEY, rbacToken, { path: '/', domain: cookieDomain });
    cookie.save(RBAC_USER_KEY, rbacUser, { path: '/', domain: cookieDomain });

    if (source === AOP_ASSISTANT_SOURCE) {
      window.location.href = `${window.location.origin}/aopAssistantLoginCallback`;
    } else if (next) {
      window.location.href = next;
    } else {
      window.location.href = `${window.location.origin}/project`;
    }
  };

  const logOut = () => {
    cookie.remove(RBAC_USER_KEY, { path: '/', domain: cookieDomain });
    window.location.href = `${window.location.origin}/login?next=${window.location.href}`;
  };

  return {
    logOut,
    loginCb,
    rbacToken: rbacTokenCookie?.[RBAC_TOKEN_KEY],
    rbacUser: rbacUserCookie?.[RBAC_USER_KEY],
  };
};
