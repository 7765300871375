import { useEffect } from 'react';
import { Avatar, Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import cookie from 'react-cookies';
import { RBAC_TOKEN_KEY, AOP_ASSISTANT_WINDOW_ID_KEY } from '@/constants/login';

import style from './LoginCallback.module.less';

function getQueryParam(key: string) {
  return new URLSearchParams(window.location.search).get(key);
}

const AopAssistantLoginCallback = () => {
  function goToVSCode() {
    let token = getQueryParam(RBAC_TOKEN_KEY);
    if (!token) {
      token = cookie.load(RBAC_TOKEN_KEY);
    }
    const windowId = window.localStorage.getItem(AOP_ASSISTANT_WINDOW_ID_KEY);
    window.location.href = `vscode://fuxi.aop-assistant/login/callback?windowId=${windowId}&token=${token}`;
  }

  useEffect(() => {
    goToVSCode();
  }, []);

  return (
    <div className={style('login-callback')}>
      <div className={style('main-content')}>
        <div className={style('title')}>
          <Avatar size={32} src="https://static.fuxi.netease.com/lowcode/index_module/index/m4gu8fgp_980040.png" />
          <span>AOP 助手</span>
        </div>
        <div className={style('icon-container', 'success')}>
          <CheckCircleFilled />
        </div>
        <div className={style('msg')}>登录成功</div>
        <div className={style('description')}>该账号拥有 AOP 助手使用权限，可返回 IDE 客户端体验。</div>
        <div className={style('operation')}>
          <Button type="primary" onClick={goToVSCode}>
            点击后登录 VS Code
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AopAssistantLoginCallback;
