import { useNavigate } from 'react-router-dom';
import { Flex } from '@fuxi/eevee-ui';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { updateTemplateDetail } from '@/store/template';
import cx from './index.module.less';

type Props = {
  isEmpty?: boolean;
  // 行业
  industryId?: number;
  // 我要 ...
  want?: string;
  // 做的任务描述
  industryName?: string;
  icon?: string;
  color?: string;
};

// 未来有需要可以抽出去
const TemplateCreateCard: React.FC<Props> = ({ isEmpty, industryId, want, industryName, icon, color }) => {
  // const industry = useAppSelector(state => state.template.industry || []);
  // const history = useHistory();
  const navigete = useNavigate();
  const dispatch = useAppDispatch();
  const templates = useAppSelector(state => state.template.templates);

  const handleCheckTpl = () => {
    // industryId 根据id跳转
    navigete(industryId !== undefined ? `/template?industry=${industryId}` : '/template');

    dispatch(
      updateTemplateDetail({
        id: templates?.[0]?.id,
        visible: true,
      })
    );
  };

  return (
    <div>
      <div className={cx('tpl-card')} style={{ borderColor: color }}>
        {isEmpty ? (
          <div className={cx('more')}>探索更多模板</div>
        ) : (
          <div>
            {/* <div className={cx('want')}>{want}</div> */}
            <Flex gap={12} flexDirection="column" alignStart>
              <div className={cx('desc')}>{industryName}</div>
              <div onClick={handleCheckTpl} className={cx('turn-to')}>
                模板详情 &gt;
              </div>
            </Flex>
            <img className={cx('img')} src={icon} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateCreateCard;
