/* eslint-disable max-depth */
import { useEffect } from 'react';
import { Spin } from 'antd';
import cookie from 'react-cookies';
import { RBAC_TOKEN_KEY, AOP_ASSISTANT_SOURCE, AOP_ASSISTANT_LOGIN_URL_KEY } from '@/constants/login';

function getQueryParam(key: string) {
  return new URLSearchParams(window.location.search).get(key);
}

const OpenIdLoginCallback = () => {
  const source = getQueryParam('source');
  let next = getQueryParam('next');
  let token = getQueryParam(RBAC_TOKEN_KEY);

  useEffect(() => {
    const aopAssistantLoginUrl = window.localStorage.getItem(AOP_ASSISTANT_LOGIN_URL_KEY);
    if (source === AOP_ASSISTANT_SOURCE && aopAssistantLoginUrl) {
      next = aopAssistantLoginUrl;
      if (!token) {
        token = cookie.load(RBAC_TOKEN_KEY);
      }
      window.location.href = `${next}?token=${token}`;
    } else if (next) {
      window.location.href = next;
    } else {
      window.location.href = `${window.location.origin}/project`;
    }
  }, []);

  return (
    <Spin size="large">
      <div style={{ height: 300 }}></div>
    </Spin>
  );
};
export default OpenIdLoginCallback;
