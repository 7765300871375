export const RBAC_TOKEN_KEY = 'RBAC_TOKEN';
export const USER_NAME = 'crowdsource';
export const SECRET = window.EEVEE_YOULING || 'pKPlj8vpHxOLtvjR6wy4P7gzqu4MG0lu';

export const CrowdsourcingAccountBackendUrl = window.EEVEE_ACCOUNT_BACKEND_URL;

export const EditionKeyInHeader = 'Edition';
export const OriginTypeKeyInHeader = 'OriginType';
export const OriginType = 'b';
export const AOP_ASSISTANT_LOGIN_URL_KEY = '__AOP_ASSISTANT_LOGIN_URL__';
export const AOP_ASSISTANT_WINDOW_ID_KEY = '__AOP_ASSISTANT_WINDOW_ID__';
export const AOP_ASSISTANT_SOURCE = 'aop-assistant';

export enum EditionType {
  Enterprise = 1,
  Personal = 2,
}
