import React from 'react';
import { Button, Flex, Table, TableColumnsType, Typography, Tooltip, TablePaginationConfig } from '@fuxi/eevee-ui';
import { ServerType } from '@/constants';
import { getRepoPullCommand } from '@/utils';
import { StatusDisplay } from '@/components/StatusDisplay';
import { PublishVersionDrawer } from '@/components/PublishVersionDrawer';
import ServiceDrawer from './ServiceDrawer';
import PublishModal from './PublishModal';
import ProjectDetailContainer from './ProjectDetailContainer';
import { useServiceOperation } from './useServiceOperation';
import { ServiceStatusOperation } from './ServiceStatusOperation';
import { serverStatusMap } from './constants';
import { PersonalPublishModal } from './PersonalPublishModal';
import cx from './ServiceList.module.less';

const { Paragraph, Text } = Typography;

const ActionButton = ({ record, projectId }: { projectId: number; record: Service; editPermission: boolean }) => {
  const { id, name, type } = record;
  const { isServiceCreator, isProjectCreator, canPublishService, canPublishPersonalService, toSyncSSHKey } =
    useServiceOperation(record);
  const btnStyle = { padding: 0 };

  return (
    <Flex column justifyCenter alignStart>
      {type === ServerType.Main ? (
        <>
          {isProjectCreator ? (
            <PublishModal server={{ id, name }} disabled={!canPublishService}>
              <Button className={cx('active-link-button')} style={btnStyle} type="link" disabled={!canPublishService}>
                发布
              </Button>
            </PublishModal>
          ) : (
            <Tooltip title="仅项目创建者有此权限">
              <Button style={btnStyle} type="link" disabled>
                发布
              </Button>
            </Tooltip>
          )}
        </>
      ) : (
        <Button style={btnStyle} type="link" onClick={toSyncSSHKey}>
          <span className={cx('active-link-button')}>同步公钥</span>
        </Button>
      )}
      {/*  私服对应人员及创建者可以发布  */}
      {type === ServerType.Personal && (
        <PersonalPublishModal
          isServiceCreator={isServiceCreator}
          isProjectCreator={isProjectCreator}
          projectId={projectId}
          serverInfo={{
            id: id,
            modules: record.modules || [],
          }}>
          <Button
            className={cx('active-link-button')}
            type="link"
            disabled={!canPublishPersonalService}
            style={btnStyle}>
            发布
          </Button>
        </PersonalPublishModal>
      )}

      {/* <div className={cx('check-publish-version-button')}>
        <DownloadModal
          children={<span>下载客户端SDK</span>}
          isPersonal={record.type === ServerType.Personal}
          serviceId={id}
          serviceName={name}></DownloadModal>
      </div> */}
      {/* {isProjectCreator &&
        record.type === ServerType.Personal &&
        (canStartPersonalService ? (
          <Button
            className={cx('active-link-button')}
            style={btnStyle}
            type="link"
            disabled={canRestoreService}
            loading={restoringServer || enablingServer}
            onClick={handleEnableService}>
            <span className={canRestoreService ? undefined : cx('active-link-button')}>启用</span>
          </Button>
        ) : (
          <Button
            className={cx('active-link-button')}
            style={btnStyle}
            type="link"
            loading={restoringServer || disablingServer}
            disabled={!canDisablePersonalService || restoringServer || disablingServer}
            onClick={handleDisableService}>
            <span
              className={
                !canDisablePersonalService || restoringServer || disablingServer ? undefined : cx('active-link-button')
              }>
              禁用
            </span>
          </Button>
        ))} */}
      <div className={cx('check-publish-version-button')}>
        <ServiceDrawer service={record} children={<span>查看日志</span>}></ServiceDrawer>
      </div>

      <div className={cx('check-publish-version-button')}>
        <PublishVersionDrawer serviceId={record.id} children={<span>查看发布版本</span>}></PublishVersionDrawer>
      </div>
    </Flex>
  );
};

interface ServiceListProps {
  editPermission: boolean;
  pagination: TablePaginationConfig;
  handlePaginationChange: (page: number, pageSize?: number) => void;
}

const ServiceList: React.FC<ServiceListProps> = props => {
  const { editPermission, pagination, handlePaginationChange } = props;
  const container = ProjectDetailContainer.useContainer();
  const {
    serviceList,
    serviceListLoading,
    currentProject: { clientRepoGitUrl, customRepoGitUrl, serviceRepoGitUrl, id: projectId },
  } = container;
  const columns: TableColumnsType<Service> = [
    {
      title: 'server_id',
      key: 'id',
      width: 120,
      dataIndex: 'id',
      render: id => <Text copyable={{ tooltips: false, text: id }}>{id}</Text>,
    },
    {
      title: '服务器名称',
      key: 'name',
      width: '15%',
      dataIndex: 'name',
      render: (name, record) => (
        <ServiceDrawer service={record}>
          <a>{name}</a>
        </ServiceDrawer>
      ),
    },
    {
      title: '所属分支',
      key: 'type',
      width: '10%',
      dataIndex: 'type',
      render: (type, record) => {
        return type === ServerType.Main ? '主分支' : `${record.creator.split('@')[0]} 的个人分支`;
      },
    },
    {
      title: '服务地址',
      key: 'serviceUrl',
      width: '20%',
      ellipsis: true,
      render: (_, record) => {
        const { serviceUrl, actorUrl, debugUrl, type } = record;

        return (
          <>
            {record?.isFrontendPublish && (
              <Paragraph
                ellipsis={{ tooltip: serviceUrl, rows: 1 }}
                copyable={serviceUrl ? { tooltips: false, text: serviceUrl } : false}>
                页面地址：{serviceUrl ? serviceUrl : '-'}
              </Paragraph>
            )}

            <Paragraph
              ellipsis={{ tooltip: actorUrl, rows: 1 }}
              copyable={actorUrl ? { tooltips: false, text: actorUrl } : false}>
              服务地址：{actorUrl ? actorUrl : '-'}
            </Paragraph>
            {type === ServerType.Personal && (
              <Paragraph
                ellipsis={{ tooltip: debugUrl, rows: 1 }}
                copyable={debugUrl ? { tooltips: false, text: debugUrl } : false}>
                调试地址：{debugUrl ? debugUrl : '-'}
              </Paragraph>
            )}
          </>
        );
      },
    },
    {
      title: 'ssh 连接命令',
      key: 'sshUrl',
      dataIndex: 'sshUrl',
      width: '20%',
      ellipsis: true,
      render: (_, record) => {
        const { sshUrl, type } = record;

        return (
          <>
            {type === ServerType.Main ? (
              <>-</>
            ) : (
              <>
                {sshUrl ? (
                  <Paragraph ellipsis={{ tooltip: sshUrl, rows: 1 }} copyable={{ tooltips: false, text: sshUrl }}>
                    {sshUrl}
                  </Paragraph>
                ) : (
                  '-'
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      title: '代码库拉取命令',
      key: 'url',
      dataIndex: 'url',
      width: '20%',
      ellipsis: true,
      render: (_, record) => {
        const { type: serviceType, version, creator } = record;
        const [clientCommand, , customCommand] = getRepoPullCommand({
          clientRepoGitUrl,
          customRepoGitUrl,
          serviceRepoGitUrl,
          serviceType,
          version,
          creator,
        });

        return (
          <>
            <Paragraph
              ellipsis={{ tooltip: clientCommand, rows: 1 }}
              copyable={clientCommand ? { tooltips: false, text: clientCommand } : false}>
              客户端：{clientCommand ? clientCommand : '-'}
            </Paragraph>

            <Paragraph
              ellipsis={{ tooltip: customCommand, rows: 1 }}
              copyable={customCommand ? { tooltips: false, text: customCommand } : false}>
              服务器代码：{customCommand ? customCommand : '-'}
            </Paragraph>

            {/* <div>
              自定义代码：
              {customCommand ? (
                <Paragraph ellipsis={{ tooltip: true, rows: 1 }} copyable>
                  {customCommand}
                </Paragraph>
              ) : (
                '-'
              )}
            </div> */}
          </>
        );
      },
    },
    {
      title: '服务状态',
      key: 'status',
      width: 120,
      dataIndex: 'status',
      render: (status, record) => {
        const serverStatus = serverStatusMap[status];
        return (
          <div>
            <Flex column justifyCenter alignStart>
              <StatusDisplay statusInfo={serverStatus}></StatusDisplay>
              <ServiceStatusOperation editPermission={editPermission} record={record} />
            </Flex>
          </div>
        );
      },
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      render: (_, record) => <ActionButton projectId={projectId} record={record} editPermission={editPermission} />,
    },
  ];
  return (
    <Table
      loading={serviceListLoading}
      rowKey="name"
      pagination={{
        pageSize: pagination?.pageSize,
        current: pagination?.current,
        showTotal: () => `共 ${serviceList?.length} 条`,
        showSizeChanger: true,
        pageSizeOptions: [5, 9, 10, 20, 50, 100],
        onChange: handlePaginationChange,
      }}
      dataSource={serviceList}
      columns={columns}
    />
  );
};

export default ServiceList;
