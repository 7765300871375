import React, { useEffect, useState } from 'react';
import { Button, Flex, Modal, Progress, Tooltip, Space } from '@fuxi/eevee-ui';
import find from 'lodash/find';
import { useAppDispatch, useAppSelector } from '@/hooks';
import IconFont from '@/components/IconFont';
import { CreateProjectFieldsValue } from '@/pages/templateCenter/CreateProjectModal';
import { mutationUseTemplate } from '@/store/template';
// import { ServiceType } from '@/typings/common';
import { handleDownloadClient } from '../utils';
import cx from './index.module.less';

const LOADING_TIME = 40;
const MAX_PERCENT = 0.92;

interface Props {
  onClose: () => void;
  fieldsValue: CreateProjectFieldsValue;
}

enum CreateProjectStatus {
  loading,
  success,
  fail,
}

const CreateProjectLoadingModal: React.FC<Props> = ({ onClose, fieldsValue }) => {
  const dispatch = useAppDispatch();
  const [percent, setPercent] = useState(0);
  const [status, updateStatus] = useState<CreateProjectStatus>(CreateProjectStatus.loading);
  const [createdProject, updateProject] = useState<Project | null>(null);

  const templateList = useAppSelector(state => state.template.templates);
  const currentTemplate = find(templateList, { id: fieldsValue?.templateId });

  // loading 的动画效果
  useEffect(() => {
    const startTime = Date.now();

    const callback = () => {
      const currentTime = Date.now();
      const currentPercent = (currentTime - startTime) / (LOADING_TIME * 1000);
      if (currentPercent < MAX_PERCENT) {
        setPercent(Math.ceil(currentPercent * 100));
        window.requestAnimationFrame(callback);
      }
    };

    window.requestAnimationFrame(callback);
  }, []);

  // 发请求
  useEffect(() => {
    (async () => {
      if (fieldsValue) {
        const res = await dispatch(mutationUseTemplate(fieldsValue)).unwrap();
        if (res?.useTemplate) {
          updateStatus(CreateProjectStatus.success);
          updateProject(res.useTemplate);
        } else {
          updateStatus(CreateProjectStatus.fail);
          console.error('创建项目失败');
        }
      }
    })();

    console.log('?????');
  }, []);

  const handleEnterProject = () => {
    window.open(`/project/${createdProject?.id}/detail/project-config/project-desc`, 'target', '');
  };

  // const handlePreviewPage = () => {
  //   const service = find(createdProject?.services, { type: ServiceType.Personal });
  //   window.open(service?.serviceUrl, 'target', '');
  // };

  return (
    <Modal
      width={538}
      footer={null}
      visible={true}
      title={`${fieldsValue?.name}项目`}
      maskClosable={status === CreateProjectStatus.loading ? false : true}
      keyboard={false}
      closable={status === CreateProjectStatus.loading ? false : true}
      onCancel={onClose}>
      <div className={cx('container')}>
        {status === CreateProjectStatus.loading && (
          <>
            <Flex justifyCenter alignCenter flexDirection={'column'}>
              <Progress
                width={120}
                strokeWidth={4}
                trailColor={'rgba(255, 255, 255, 0.3)'}
                strokeColor={'#4071F9'}
                percent={percent}
                type={'circle'}
              />
              <div className={cx('progress-text')}>项目创建中...</div>
            </Flex>
            <div className={cx('message')}>
              <div className={cx('caption')}>自动创建过程包含：</div>
              <div className={cx('title')}>初始化配置</div>
              <div className={cx('content')}>
                <p>a、可视化编辑的逻辑、实体、配置表、数据类、客户端rpc等</p>
                <p>b、自定义代码</p>
                <p>c、AI能力、众包任务等外部依赖服务</p>
                <p>d、客户端</p>
              </div>
              <div className={cx('title')}>启用并启动用户测试服</div>
            </div>
          </>
        )}
        {status === CreateProjectStatus.success && (
          <>
            <Flex justifyCenter alignCenter flexDirection={'column'}>
              <Progress
                width={120}
                percent={100}
                strokeWidth={4}
                status={'success'}
                type={'circle'}
                strokeColor={'#4cbd42'}
                format={() => {
                  return <IconFont type={'icon-shangchuanchenggong'} className={cx('success-icon')}></IconFont>;
                }}
              />
              <div className={cx('progress-success-text')}>
                <p>成功创建项目 {fieldsValue?.name}！</p>
                <p>您的测试服已启动，接下来您可以：</p>
              </div>
            </Flex>
            <Flex justifyCenter alignCenter>
              <Button size={'large'} type="primary" style={{ width: 230 }} onClick={handleEnterProject}>
                进入项目
              </Button>
            </Flex>
            <Flex justifyBetween alignCenter className={cx('or')}>
              <div className={cx('or-line')}></div>
              <div className={cx('or-text')}>或者</div>
              <div className={cx('or-line')}></div>
            </Flex>
            <Flex justifyCenter alignCenter>
              {currentTemplate?.clientSample && (
                <Flex justifyCenter alignCenter className={cx('item')}>
                  <Flex alignCenter>
                    <div className={cx('item-title')}>示例客户端</div>
                    <Tooltip title={'下载示例客户端，运行后连接测试服看看吧'}>
                      <Space>
                        <IconFont type="icon-tishi" className={cx('icon')} />
                      </Space>
                    </Tooltip>
                  </Flex>
                  <Button
                    size={'small'}
                    type={'primary'}
                    onClick={() => handleDownloadClient(createdProject?.id, fieldsValue?.templateVersion)}>
                    下载
                  </Button>
                </Flex>
              )}
              {/* {createdProject?.includePage && (
                <Flex justifyCenter alignCenter className={cx('item')}>
                  <Flex alignCenter>
                    <div className={cx('item-title')}>页面</div>
                    <Tooltip title={'点击查看新项目的页面效果'}>
                      <Space>
                        <IconFont type="icon-tishi" className={cx('icon')} />
                      </Space>
                    </Tooltip>
                  </Flex>
                  <Button size={'small'} type={'primary'} onClick={handlePreviewPage}>
                    预览
                  </Button>
                </Flex>
              )} */}
              <Flex justifyCenter alignCenter className={cx('item')}>
                <Flex alignCenter>
                  <div className={cx('item-title')}>项目详情</div>
                  <Tooltip title={'点击查看新项目详情'}>
                    <Space>
                      <IconFont type="icon-tishi" className={cx('icon')} />
                    </Space>
                  </Tooltip>
                </Flex>
                <Button size={'small'} type={'primary'} onClick={handleEnterProject}>
                  查看
                </Button>
              </Flex>
            </Flex>
          </>
        )}
        {status === CreateProjectStatus.fail && (
          <Flex justifyCenter alignCenter flexDirection={'column'}>
            <Progress width={120} percent={100} strokeWidth={4} status={'exception'} type={'circle'} />
            <div className={cx('progress-success-text')}>
              <p>创建项目 {fieldsValue?.name} 失败</p>
              <p>请稍后再试</p>
            </div>
          </Flex>
        )}
      </div>
    </Modal>
  );
};

export default CreateProjectLoadingModal;
