import { get } from 'lodash';
import { AxiosResponse } from 'axios';
import { message } from '@fuxi/eevee-ui';

export default function responseHandler(response: AxiosResponse) {
  const errorCodesList = [500, 502, 503, 504, 400, 5000, '5000'];
  console.log('response', response, response.data.code);
  const data = get(response, 'data.data', response.data);
  if (errorCodesList.includes(response.data.code)) {
    message.error(response.data.msg || response.data.message || '服务端异常');
    return Promise.reject(response);
  }

  if (response.data.code === 401) {
    message.error(response.data.msg || '服务端异常');
    return Promise.reject(response);
  }

  return { ...response, data };
}

export const shuyuanResponseHandler = (response: AxiosResponse) => {
  const errorCodesList = [500, 502, 503, 504, 400];
  const data = get(response, 'data.data', response.data);
  const timeout = get(response, 'data.timeout', response.data.timeout);
  const timeRanges = get(response, 'data.timeRanges', response.data.timeRanges);
  if (errorCodesList.includes(response.data.code)) {
    message.error(response.data.msg || '服务端异常');
    return Promise.reject(response);
  }

  if (response.data.code === 401) {
    message.error(response.data.msg || '服务端异常');
    return Promise.reject(response);
  }

  return { ...response, data, timeout, timeRanges };
};

export function accountResponseHandler(response: AxiosResponse) {
  const unAuthorizedCode = [1, 113, 2100];
  const data = get(response, 'data.data', response.data);
  const code = get(response, 'data.code', response.status);
  const msg = get(response, 'data.message');

  if (code !== 0) {
    if (!unAuthorizedCode.includes(code)) {
      message.error(msg);
    }
    return Promise.reject(msg);
  }
  return { ...response, data };
}
