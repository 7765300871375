import React, { useEffect, useState } from 'react';
import { Form, Modal } from '@fuxi/eevee-ui';
import find from 'lodash/find';
import ProjectForm from '@/pages/project/ProjectForm';
import { baseUrlSuffix, modalWidth } from '@/pages/project/const';
import { useAppSelector } from '@/hooks';
import CreateProjectLoadingModal from '@/pages/templateCenter/CreateProjectLoadingModal';
import { useImgUpload } from '@/hooks/useImgUpload';
import { CreateProjectType } from '@/constants/ownership';

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface CollectionCreateFormProps {
  visible: boolean;
  onCreate?: (values: Values) => void;
  onClose: () => void;
}

export type CreateProjectFieldsValue = {
  name: string;
  // identifier: string;
  description: string;
  includeBusinessData: boolean;
  templateId: string;
  templateVersion: string;
  iconUrl: string;
  type: CreateProjectType;
} | null;

const CreateProjectModal: React.FC<CollectionCreateFormProps> = ({ visible, onClose }) => {
  const [form] = Form.useForm<CreateProjectFieldsValue>();
  const { iconFile, setIconFile, imgChanged, setImgChanged, waitImgUpload } = useImgUpload();
  const userInfo = useAppSelector(state => state.user);
  const { id } = useAppSelector(state => state.template.detail);
  const list = useAppSelector(state => state.template.templates);
  const data = find(list, { id });

  const [loadingVisible, setLoadingVisible] = useState(false);
  const [fieldsValue, setFieldsValue] = useState<CreateProjectFieldsValue>(null);

  const initialValues = {
    name: '',
    baseUrl: baseUrlSuffix,
    identifier: '',
    description: '',
    type: CreateProjectType.PERSONAL,
    users: [{ email: userInfo.email, name: userInfo.name, roles: userInfo.roles || [] }],
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  if (loadingVisible) {
    return <CreateProjectLoadingModal onClose={() => setLoadingVisible(false)} fieldsValue={fieldsValue} />;
  }

  if (!visible) {
    return null;
  }

  if (data) {
    const { name } = data;
    return (
      <>
        <Modal
          width={modalWidth}
          visible={visible}
          title={`从模板 ${name} 创建项目`}
          okText="确定"
          cancelText="取消"
          onCancel={() => onClose()}
          onOk={() => {
            form
              .validateFields()
              .then(async values => {
                const payload = {
                  name: values?.name || '',
                  // identifier: values?.identifier || '',
                  description: values?.description || '',
                  includeBusinessData: false,
                  templateId: data.id,
                  templateVersion: data.versionInfo.version,
                  iconUrl: '',
                  type: values?.type || CreateProjectType.ENTERPRISE,
                };
                await waitImgUpload(imgChanged, iconFile, payload);
                setFieldsValue(payload);
                setLoadingVisible(true);
                setImgChanged(false);
                onClose();
                //onCreate(values);
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}>
          <ProjectForm
            setIconFile={setIconFile}
            setImgChanged={setImgChanged}
            isEdit={false}
            form={form}
            initialValues={initialValues}
            userInfo={userInfo}
          />
        </Modal>
      </>
    );
  } else if (loadingVisible) {
    return <CreateProjectLoadingModal onClose={() => setLoadingVisible(false)} fieldsValue={fieldsValue} />;
  } else {
    return null;
  }
};

export default CreateProjectModal;
