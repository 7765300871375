import { ServiceStatus } from '@/typings/common';

const Level = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  DEFAULT: 'default',
};

export const LevelColorMap = {
  [Level.ERROR]: '#f76965',
  [Level.SUCCESS]: '#2ba316',
  [Level.DEFAULT]: '#979797',
  [Level.INFO]: '#36bd1f',
};

const serverStatusColorMap = {
  error: '#de5550',
  success: '#36bd1f',
  info: '#36bd1f',
  default: 'rgba(255, 255, 255, 0.3)',
}

export const serverStatusMap: Record<string, { label: string; level: string; color: string; }> = {
  [ServiceStatus.Error]: {
    label: '异常',
    level: 'error',
    color: serverStatusColorMap.error,
  },
  [ServiceStatus.Running]: {
    label: '运行中',
    level: 'success',
    color: serverStatusColorMap.success,
  },
  [ServiceStatus.Creating]: {
    label: '创建中',
    level: 'info',
    color: serverStatusColorMap.info,
  },
  [ServiceStatus.Starting]: {
    label: '启动中',
    level: 'info',
    color: serverStatusColorMap.info,
  },
  [ServiceStatus.Paused]: {
    label: '已暂停',
    level: 'default',
    color: serverStatusColorMap.default,
  },
  [ServiceStatus.Pending]: {
    label: '未开启',
    level: 'default',
    color: serverStatusColorMap.default,
  },
  [ServiceStatus.InitError]: {
    label: '创建失败',
    level: 'error',
    color: serverStatusColorMap.error,
  },
  [ServiceStatus.UnStart]: {
    label: '未开启',
    level: 'error',
    color: serverStatusColorMap.default,
  },
  [ServiceStatus.Closing]: {
    label: '关闭中',
    level: 'info',
    color: serverStatusColorMap.info,
  },
  [ServiceStatus.Restarting]: {
    label: '重启中',
    level: 'info',
    color: serverStatusColorMap.info,
  },
  [ServiceStatus.Releasing]: {
    label: '发布中',
    level: 'info',
    color: serverStatusColorMap.info,
  },
  [ServiceStatus.Auditing]: {
    label: '审核中',
    level: 'info',
    color: serverStatusColorMap.info,
  },
  [ServiceStatus.AuditFial]: {
    label: '审核失败',
    level: 'error',
    color: serverStatusColorMap.error,
  },
};

export enum ProjectRole {
  TplCreator = 'template-creator',
  Maintainer = 'maintainer',
}
