/* eslint-disable max-lines */
import { ExceptionOutlined } from '@ant-design/icons';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import {
  AbilityPublic,
  AbilityPublicDetail,
  AbilityPublicVersionDetail,
  AbilityTransfer,
  //项目能力
  AbilityProject,
  AbilityProjectDetail,
  AbilityProjectVersionDetail,
  CreateProjectAbility,
  ReleaseProjectAbility,
  NewAIBodyVersion,
  //公共数据集
  DataSetPublic,
  DataSetPublicDetail,
  DatasetPublicVersionDetail,
  //项目数据集
  DataSetProject,
  CreateDataSet,
  DatasetProjectDetail,
  DatasetProjectVersionDetail,
  DatasetProjectCreateVersion,
  DatasetProjectImportVersion,
  //训练任务
  TrainingTask,
  CreateTraining,
  TaskDetail,
  //在线服务
  OnlineService,
  ServiceDetail,
  ReleaseService,
} from 'ai-training';

// 首页
import { Icon } from '@fuxi/eevee-ui';
import Home from '@/pages/home/Home';
// 异常页
import ForbiddenPage from '@/pages/exception/403';
import NotFoundPage from '@/pages/exception/404';
// 项目管理页
import ProjectManage from '@/pages/project';
import ProjectDetail from '@/pages/project/ProjectDetail';
import ProjectDetailMini from '@/pages/project/ProjectDetailMini';
// Mini 项目评审
import MiniReview from '@/pages/miniReview';
//Mini项目评审结果汇总
import ResultSummary from '@/pages/resultSummary/resultSummary';
import QuestionReview from '@/pages/miniReview/components/QuestionReview';
// 模板中心
import TemplateCenter from '@/pages/templateCenter';
import { ProjectAiCapApproval } from '@/pages/project/ProjectDetail/ProjectAiCapApproval';
import BasicLayout from '@/layout/BasicLayout';
import Login from '@/pages/login';
import OpenIdLoginCallback from '@/pages/openIdLoginCallback';
import AopAssistantLoginCallback from '@/pages/aopAssistantLoginCallback';
// import { ProjectAiCapConfig } from '@/pages/project/ProjectDetail/ProjectAiCapConfig';
// import { AiCapabilityContainer } from '@/pages/project/ProjectDetail/AiCapabilityContainer';

//ProjectDetail下子页面（Platform）
import { ProjectDesc } from '@/pages/project/ProjectDetail/ProjectDesc';
import UserManage from '@/pages/project/ProjectDetail/UserManage';
import { CustomImage } from '@/pages/project/ProjectDetail/CustomImage';
import { TerminalManage } from '@/pages/project/ProjectDetail/TerminalManage';
import ConsumeRecord from '@/pages/project/ConsumeRecord';
import { ProjectServiceList } from '@/pages/project/ProjectDetail/ProjectServiceList';
import IconFont from '@/components/IconFont';
import { BiBoard } from '@/pages/project/ProjectDetail/BiBoard';
import { ManageServiceConfig } from '@/pages/manageServiceConfig';
import VirtualTerminal from '../pages/project/components/VirtualTerminal';

/**
 * 侧边栏及路由配置
  {
    name: '表格页',                       侧边栏以及面包屑的显示
    breadcrumb: '表格页XXX',              默认为name，面包屑的值，如果面包屑和侧边栏显示不一致则需要填写此字段
    icon: 'table',                        侧边栏显示的icon
    path: '/table',                       路由路径
    redirect: '/table/basic',             路由重定向
    element: Table,                     路由对应的组件，如果是逻辑分组，则不需要提供component值
    exact: true,                          默认为true，是否完全匹配
    hidden: false,                        默认为false，如果设为true，则不会显示在侧边栏中
    permissionCodes: ['basicTable']       路由对应的权限码
    children: [{}]                        该路由的子路由
  }
*/
export interface IRouterExtra {
  path: string;
  name?: string;
  isAiConfig?: boolean;
  children?: IRouterExtra[];
  icon?: React.ReactNode;
  isMini?: boolean;
  onlyInMini?: boolean;
  onlyInTerminal?: boolean;
}

const routerConfig: RouteObject[] = [
  {
    path: '*',
    element: <Navigate to="/project" />,
  },
  {
    path: '/',
    element: <BasicLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="project" />,
      },
      {
        path: 'project',
        loader: () => ({ title: '我的项目' }),
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ProjectManage />,
          },
          {
            path: 'service-config',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <ManageServiceConfig />,
              },
            ],
          },
          {
            path: ':currentProjectId',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate to="detail" />,
              },
            ],
          },
          {
            path: ':currentProjectId/detail',
            loader: () => ({ title: '项目详情' }),
            element: <ProjectDetail />,
            children: [
              {
                path: '',
                element: <Navigate to="project-config" />,
              },
              {
                path: 'project-config',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <Navigate to="project-desc" />,
                  },
                  {
                    path: 'project-desc',
                    loader: () => ({ title: '项目描述' }),
                    element: <ProjectDesc />,
                  },
                  {
                    path: 'service-list',
                    element: <ProjectServiceList />,
                  },
                  {
                    path: 'user-manage',
                    element: <UserManage editPermission={true} />,
                  },
                  {
                    path: 'custom-image',
                    element: <CustomImage />,
                  },
                  {
                    path: 'terminal-manage',
                    element: <TerminalManage />,
                  },
                  {
                    path: 'virtual-terminal',
                    element: <VirtualTerminal />,
                  },
                  {
                    path: 'bi-board',
                    element: <BiBoard />,
                  },
                ],
              },
              {
                path: ':projectId/ai-config',
                element: <Outlet />,
                children: [
                  // {
                  //   path: 'ability',
                  //   element: <Outlet />,
                  //   children: [

                  //   ],
                  // },
                  {
                    path: 'ability/public-ability',
                    loader: () => ({ title: '公共能力' }),
                    element: <Outlet />,
                    children: [
                      {
                        index: true,
                        element: <AbilityPublic readOnly={false} />,
                      },
                      {
                        path: 'transfer',
                        loader: () => ({ title: '能力调用' }),
                        element: <AbilityTransfer />,
                      },
                      {
                        path: ':abilityId',
                        loader: () => ({ title: '能力详情' }),
                        element: <Outlet />,
                        children: [
                          {
                            index: true,
                            element: <AbilityPublicDetail />,
                          },
                          {
                            path: ':versionId',
                            loader: () => ({ title: '版本详情' }),
                            element: <AbilityPublicVersionDetail />,
                          },
                          {
                            // path transfer加入查询参数, versionId
                            path: 'transfer/:versionId',
                            loader: () => ({ title: '能力调用' }),
                            element: <AbilityTransfer />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'ability/project-ability',
                    loader: () => ({ title: '项目能力' }),
                    element: <Outlet />,
                    children: [
                      {
                        index: true,
                        element: <AbilityProject />,
                      },
                      {
                        path: ':abilityId',
                        loader: () => ({ title: '能力详情' }),
                        element: <Outlet />,
                        children: [
                          {
                            index: true,
                            element: <AbilityProjectDetail />,
                          },
                          {
                            path: ':versionId',
                            loader: () => ({ title: '版本详情' }),
                            element: <AbilityProjectVersionDetail />,
                          },
                        ],
                      },
                      {
                        path: 'create',
                        loader: () => ({ title: '创建能力' }),
                        element: <CreateProjectAbility />,
                      },
                      {
                        path: 'release/:abilityId',
                        loader: () => ({ title: '能力发布' }),
                        element: <ReleaseProjectAbility />,
                      },
                      {
                        path: 'version/:abilityId',
                        loader: () => ({ title: '新建版本' }),
                        element: <NewAIBodyVersion />,
                      },
                      {
                        path: 'update-version/:abilityId',
                        loader: () => ({ title: '编辑版本' }),
                        element: <NewAIBodyVersion />,
                      },
                      {
                        path: 'copy-version/:abilityId',
                        loader: () => ({ title: '拷贝新建版本' }),
                        element: <NewAIBodyVersion />,
                      },
                    ],
                  },
                  {
                    path: 'data-set',
                    children: [
                      {
                        path: 'public',
                        loader: () => ({ title: '公共数据集' }),
                        element: <Outlet />,
                        children: [
                          {
                            index: true,
                            element: <DataSetPublic />,
                          },
                          {
                            path: ':dataId',
                            element: <Outlet />,
                            loader: () => ({ title: '数据集详情' }),
                            children: [
                              {
                                index: true,
                                element: <DataSetPublicDetail />,
                              },
                              {
                                path: ':versionId',
                                loader: () => ({ title: '版本详情' }),
                                element: <DatasetPublicVersionDetail />,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'project',
                        loader: () => ({ title: '项目数据集' }),
                        element: <Outlet />,
                        children: [
                          {
                            index: true,
                            element: <DataSetProject />,
                          },
                          {
                            path: ':dataId',
                            loader: () => ({ title: '数据集详情' }),
                            element: <Outlet />,
                            children: [
                              {
                                index: true,
                                element: <DatasetProjectDetail />,
                              },
                              {
                                path: ':versionId',
                                loader: () => ({ title: '版本详情' }),
                                element: <DatasetProjectVersionDetail />,
                              },
                              {
                                path: 'createVersion',
                                loader: () => ({ title: '新增版本' }),
                                element: <DatasetProjectCreateVersion />,
                              },
                              {
                                path: 'importVersion',
                                loader: () => ({ title: '导入' }),
                                element: <DatasetProjectImportVersion />,
                              },
                            ],
                          },
                          {
                            path: 'create',
                            loader: () => ({ title: '创建数据集' }),
                            element: <CreateDataSet />,
                          },
                          {
                            path: 'createVersion',
                            loader: () => ({ title: '新增版本' }),
                            element: <DatasetProjectCreateVersion />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'training-task',
                    loader: () => ({ title: '训练任务' }),
                    element: <Outlet />,
                    children: [
                      {
                        index: true,
                        element: <TrainingTask />,
                      },
                      {
                        path: 'create',
                        loader: () => ({ title: '创建训练' }),
                        element: <CreateTraining />,
                      },
                      {
                        path: ':taskId',
                        loader: () => ({ title: '训练任务详情' }),
                        element: <TaskDetail />,
                      },
                    ],
                  },
                  {
                    path: 'online-service',
                    loader: () => ({ title: '在线服务' }),
                    element: <Outlet />,
                    children: [
                      {
                        index: true,
                        element: <OnlineService />,
                      },
                      {
                        path: 'release-service',
                        loader: () => ({ title: '发布服务' }),
                        element: <ReleaseService />,
                      },
                      {
                        path: ':serviceId',
                        loader: () => ({ title: '服务详情' }),
                        element: <ServiceDetail />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'consume-record',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    loader: () => ({ title: '消费记录' }),
                    element: <ConsumeRecord />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'miniDetail',
        loader: () => ({ title: '项目详情' }),
        element: <ProjectDetailMini />,
      },
      {
        path: 'template',
        loader: () => ({ title: '模板中心' }),
        element: <TemplateCenter />,
      },
      {
        path: 'ai-capability',
        loader: () => ({ title: '公共能力' }),
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <AbilityPublic readOnly={true} />,
          },
          {
            path: ':abilityId',
            loader: () => ({ title: '能力详情' }),
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <AbilityPublicDetail readOnly={true} />,
              },
              {
                path: ':versionId',
                loader: () => ({ title: '版本详情' }),
                element: <AbilityPublicVersionDetail />,
              },
            ],
          },
          // {
          //   path: 'transfer',
          //   loader: () => ({ title: '能力调用' }),
          //   element: <AbilityTransfer />,
          // },
        ],
      },
      {
        path: 'review',
        loader: () => ({ title: '评审结果' }),
        element: <Outlet />,
        // permissionCodes: ['mini-maintainer'],
        children: [
          {
            index: true,
            element: <MiniReview />,
          },
          {
            path: 'questionnaire',
            loader: () => ({ title: '评审问卷' }),
            element: <QuestionReview />,
          },
          {
            path: 'resultSummary',
            loader: () => ({ title: '项目结果汇总' }),
            element: <ResultSummary />,
          },
        ],
      },
      {
        path: 'cap-approval',
        loader: () => ({ title: '能力评审' }),
        element: <ProjectAiCapApproval />,
      },
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'openIdLoginCallback',
    element: <OpenIdLoginCallback />,
  },
  {
    path: 'aopAssistantLoginCallback',
    element: <AopAssistantLoginCallback />,
  },
];

export const menuConfig: RouteConfig[] = [
  {
    name: '我的项目',
    path: '/project',
    element: ProjectManage,
    children: [
      {
        name: '项目详情',
        path: '/detail',
        hidden: true,
        element: ProjectDetail,
      },
      {
        name: '项目详情',
        path: '/miniDetail',
        hidden: true,
        element: ProjectDetailMini,
      },
      {
        name: '服务配置',
        path: '/service-config',
        hidden: true,
        element: ManageServiceConfig,
      }
    ],
  },
  {
    name: '评审结果',
    path: '/review',
    element: MiniReview,
    permissionCodes: ['mini-maintainer'],
    children: [
      {
        name: '评审问卷',
        path: '/questionnaire',
        element: QuestionReview,
        hidden: true,
      },
      {
        name: '项目结果汇总',
        path: '/resultSummary',
        element: ResultSummary,
        hidden: true,
      },
    ],
  },
  {
    name: '模板中心',
    path: '/template',
    element: TemplateCenter,
  },
  {
    name: '公共能力',
    path: '/ai-capability',
    element: () => <AbilityPublic readOnly={true} />,
    children: [
      {
        path: '/:abilityId',
        name: '能力详情',
        hidden: true,
        element: () => <AbilityPublicDetail readOnly={true} />,
        children: [
          {
            name: '版本详情',
            path: '/:versionId',
            element: AbilityPublicVersionDetail,
            hidden: true,
          },
        ],
      },
      // {
      //   path: '/transfer',
      //   element: AbilityTransfer,
      //   hidden: true,
      //   name: '能力调用',
      // },
    ],
  },
  // {
  //   name: '有灵ml',
  //   path: '/ml',
  // },
  {
    name: '异常页',
    icon: <ExceptionOutlined />,
    path: '/exception',
    hidden: true,
    redirect: '/exception/403',
    children: [
      {
        name: '403',
        path: '/403',
        element: ForbiddenPage,
      },
      {
        name: '404',
        path: '/404',
        element: NotFoundPage,
      },
    ],
  },
  {
    path: '/home',
    element: Home,
    hidden: true,
  },
  {
    name: '能力评审',
    path: '/cap-approval',
    element: ProjectAiCapApproval,
    hidden: true,
  },
  {
    redirect: '/project',
    hidden: true,
  },
];

export const routerExtras: IRouterExtra[] = [
  {
    path: 'project-config',
    name: '项目信息',
    isMini: true,
    children: [
      {
        path: 'project-config/project-desc',
        name: '项目描述',
        icon: <IconFont style={{ fontSize: 16 }} type="icon-mobanmiaoshu" />,
        isMini: true,
      },
      {
        path: 'project-config/service-list',
        name: '服务器管理',
        icon: <IconFont style={{ fontSize: 16 }} type="icon-fuwuliebiao" />,
        isMini: true,
      },
      {
        path: 'project-config/user-manage',
        name: '权限管理',
        icon: <IconFont style={{ fontSize: 16 }} type="icon-renyuanguanli" />,
        isMini: true,
      },
      // {
      //   path: 'project-config/custom-image',
      //   name: '镜像管理',
      //   icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="自定义镜像-项目中心" />,
      //   isMini: false,
      // },
      // {
      //   path: 'project-config/terminal-manage',
      //   name: '设备终端',
      //   icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="终端管理-项目中心" />,
      //   isMini: false,
      //   onlyInTerminal: true,
      // },
      // {
      //   path: 'project-config/virtual-terminal',
      //   name: '虚拟终端',
      //   icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="xunizhongduan" />,
      //   isMini: false,
      // },
      // {
      //   path: 'project-config/bi-board',
      //   name: 'BI看板',
      //   icon: <IconFont style={{ fontSize: 16 }} type="icon-a-bidashboard" />,
      //   isMini: true,
      //   onlyInMini: true,
      // },
    ],
  },
  {
    path: 'ai-config',
    isAiConfig: true,
    name: '能力',
    children: [
      {
        path: 'ai-config/ability/public-ability',
        isAiConfig: true,
        name: '公共能力',
        icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="project-agent" />,
      },
      {
        path: 'ai-config/ability/project-ability',
        isAiConfig: true,
        name: '项目能力',
        icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="project-agent" />,
      },
      // {
      //   path: 'ai-config/ability',
      //   isAiConfig: true,
      //   name: '能力',
      //   icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="project-agent" />,
      //   children: [
      //     {
      //       path: 'ai-config/ability/public-ability',
      //       isAiConfig: true,
      //       name: '公共能力',
      //       icon: <div style={{ width: 16 }} />,
      //     },
      //     {
      //       path: 'ai-config/ability/project-ability',
      //       isAiConfig: true,
      //       name: '项目能力',
      //       icon: <div style={{ width: 16 }} />,
      //     },
      //   ],
      // },
      // {
      //   path: 'ai-config/data-set',
      //   isAiConfig: true,
      //   name: '数据集',
      //   icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="data-set" />,
      //   children: [
      //     {
      //       path: 'ai-config/data-set/public',
      //       isAiConfig: true,
      //       name: '公共数据集',
      //       icon: <div style={{ width: 16 }} />,
      //     },
      //     {
      //       path: 'ai-config/data-set/project',
      //       isAiConfig: true,
      //       name: '项目数据集',
      //       icon: <div style={{ width: 16 }} />,
      //     },
      //   ],
      // },
      // {
      //   path: 'ai-config/training-task',
      //   isAiConfig: true,
      //   name: '训练任务',
      //   icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="task-training" />,
      // },
      {
        path: 'ai-config/online-service',
        isAiConfig: true,
        name: '在线服务',
        icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="online-service" />,
      },
    ],
    isMini: false,
  },
  {
    path: 'consume-record',
    name: '使用监控',
    isMini: false,
  },
];

export const mlExtras: IRouterExtra[] = [
  {
    path: 'ai-platform',
    isAiConfig: false,
    name: '能力广场',
    icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="project-agent" />,
    children: [
      {
        path: '/ai-platform/ml-public-ability',
        name: '公共能力',
      },
      {
        path: '/ai-platform/ml-project-ability',
        name: '项目能力',
      },
    ],
    isMini: false,
  },
  {
    path: '/dataset',
    name: '数据集',
    isMini: false,
    icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="data-set" />,
  },
  {
    path: '/ml-training-task',
    name: '训练任务',
    isMini: false,
    icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="task-training" />,
  },
  {
    path: '/model-service',
    name: '模型服务',
    isMini: false,
    icon: <Icon style={{ fontSize: 16, marginRight: 8 }} name="online-service" />,
  },
];

export default routerConfig;
