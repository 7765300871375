import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input, FormInstance, Tooltip, Flex } from '@fuxi/eevee-ui';
import { CloseCircleTwoTone, InfoCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Radio } from 'antd';
import { CreateProjectType } from '@/constants/ownership';

import {
  // baseUrlSuffix,
  formLayout,
  descriptionAutoSize,
  descriptionMaxWidth,
  nullImage,
  acceptImageType,
} from './const';
import style from './index.module.less';

type ProjectFormProps = {
  form: FormInstance<any>;
  initialValues: Partial<Project>;
  projectIdDisabled?: boolean;
  userInfo?: User;
  setIconFile?: any;
  setImgChanged?: any;
  ref?: any;
  isEdit: boolean;
};
// const idRegexp = /^[a-z][0-9a-z\\-]+[0-9a-z]$/;
// const onlySmallCharacterOrNumberReg = /^[0-9a-z\\-]{1,}$/;
// const startsWithSmallCharacterReg = /^[a-z].*$/;
// const endsWithSmallCharacterOrNumberReg = /^.*[a-z0-9]$/;

const tenMB = 10485760;
let ProjectForm: React.FC<ProjectFormProps> = (props, ref) => {
  const { form, initialValues, setIconFile, setImgChanged, isEdit } = props;
  // const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   form.setFieldsValue({ baseUrl: e.target.value + baseUrlSuffix });
  // };
  const [loading, setIsLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState(initialValues.iconUrl);
  const [previewImage, setPreviewImage] = useState('');

  useImperativeHandle(ref, () => ({
    resetPreviewImage: () => {
      setPreviewImage(initialValues.iconUrl || '');
    },
  }));

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const removeIcon = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setPreviewImage('');
    setImgUrl('');
    setIconFile('');
    setImgChanged(true);
  };

  const showInitialImgUrl = !previewImage && imgUrl?.startsWith('http');
  const showImage = (
    <div className={style('show-image-container')}>
      <img className={style('show-image')} src={showInitialImgUrl ? imgUrl : previewImage} alt="" />
      <div onClick={e => removeIcon(e)} className={style('image-icon-container')}>
        <CloseCircleTwoTone className={style('image-icon')} />
      </div>
    </div>
  );

  const uploadButton =
    showInitialImgUrl || (previewImage !== '' && previewImage !== nullImage) ? (
      showImage
    ) : (
      <div className={style('img-container')}>{loading ? <LoadingOutlined /> : <PlusOutlined />}</div>
    );
  // eslint-disable-next-line react-func/max-lines-per-function
  const handleFileChange = async (e: any) => {
    const fileList = e.target.files[0];
    if (!fileList) return;

    if (fileList.size > tenMB) {
      message.error('上传图片大小不能超过10M');
      return;
    }

    if (!acceptImageType.includes(fileList.type)) {
      message.error('上传图片格式错误, 仅支持png, jpg, jpeg');
      return;
    }

    setIsLoading(true);
    if (!fileList?.url && !fileList?.preview) {
      fileList.preview = await getBase64(fileList);
    }
    setIconFile(fileList);
    setImgChanged(true);
    setPreviewImage(fileList.url || fileList.preview);
    setIsLoading(false);
    e.target.value = '';
  };

  return (
    <Form
      className={style('create-form')}
      initialValues={{ ...initialValues, users: initialValues?.users?.map((item: User) => item.email) }}
      form={form}
      {...formLayout}
      labelCol={{ span: 6 }}>
      <Form.Item label="项目名称" name="name" rules={[{ required: true, message: '项目名称不能为空' }]}>
        <Input showCount autoComplete="off" maxLength={20} placeholder="1-20位字符组成" />
      </Form.Item>
      {/* <Form.Item
        label={
          <Flex gap={4} alignCenter>
            项目标识
            <Tooltip
              title={
                <ul style={{ margin: 0, paddingLeft: 20 }}>
                  <li>项目唯一标识</li>
                  <li>3-20位字符</li>
                  <li>只能包含小写字母、数字、中划线</li>
                  <li>须以字母开头，须以字母数字结尾</li>
                </ul>
              }>
              <InfoCircleOutlined style={{ fontSize: 12, cursor: 'pointer' }} />
            </Tooltip>
          </Flex>
        }
        name="identifier"
        rules={[
          {
            required: true,
            message: '项目标识不能为空',
          },
          { min: 3, message: '最少3个字符' },
          {
            pattern: new RegExp(onlySmallCharacterOrNumberReg, 'g'),
            message: '项目标识中只能包含小写字母、数字、中划线',
          },
          {
            pattern: new RegExp(startsWithSmallCharacterReg, 'g'),
            message: '项目标识须以小写字母开头',
          },
          {
            pattern: new RegExp(endsWithSmallCharacterOrNumberReg, 'g'),
            message: '项目标识须以小写字母/数字结尾',
          },
          {
            // lodash的debounce不生效 会延迟校验 因为返回结果不是promise
            validator: debounce(async (_, value) => {
              if (projectIdDisabled || !value || !idRegexp.test(value)) {
                return Promise.resolve();
              }
              const isOk = await client
                .query(CHECK_IDENTIFIER, { identifier: value })
                .then(res => (res as { projectIdentifier: boolean })?.projectIdentifier);
              return isOk ? Promise.resolve() : Promise.reject('该项目标识已存在');
            }, 1000),
          },
        ]}>
        <Input
          onChange={handleUrlChange}
          maxLength={20}
          showCount
          disabled={projectIdDisabled}
          placeholder="项目唯一标识，创建后不可修改"
          autoComplete="off"
        />
      </Form.Item> */}
      {/* <Form.Item
        label="项目域名"
        name="baseUrl"
        rules={[
          {
            required: true,
          },
        ]}>
        <Input disabled />
      </Form.Item> */}
      {/* {userInfo && !isEdit && (
        <Form.Item label="项目创建者" name="users" rules={[{ required: true, message: '创建者不能为空' }]}>
          <Select disabled style={{ maxHeight: 70, overflow: 'auto' }} mode="multiple" placeholder="请选择成员">
            <Select.Option value={userInfo.email}>
              {userInfo.fullName} {userInfo.email}
            </Select.Option>
          </Select>
        </Form.Item>
      )} */}
      {!isEdit && (
        <Form.Item
          required
          label={
            <Flex gap={4} alignCenter>
              归属空间
              <Tooltip
                style={{
                  width: 'content',
                }}
                title={
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <div>企业：项目成员拥有标注数据查看和管理权限</div>
                    <div>个人：仅项目创建者拥有标注数据查看和管理权限</div>
                  </div>
                }>
                <InfoCircleOutlined style={{ fontSize: 12, cursor: 'pointer' }} />
              </Tooltip>
            </Flex>
          }
          name="type"
          rules={[{ required: true, message: '必填' }]}>
          <Radio.Group defaultValue={CreateProjectType.PERSONAL}>
            <Radio value={CreateProjectType.PERSONAL}>个人</Radio>
            <Radio value={CreateProjectType.ENTERPRISE}>企业</Radio>
          </Radio.Group>
        </Form.Item>
      )}
      <Form.Item label="项目描述" name="description" className={style('desciption-container')}>
        <Input.TextArea
          style={{ wordBreak: 'break-all' }}
          autoComplete="off"
          autoSize={descriptionAutoSize}
          showCount
          maxLength={descriptionMaxWidth}
          placeholder="请输入项目描述，500字符以内"
        />
      </Form.Item>
      <Form.Item label="项目图标" name="icon">
        <input
          accept={acceptImageType.join()}
          id="uploadInput"
          className={style('upload-input')}
          type="file"
          onChange={e => handleFileChange(e)}></input>
        <label className={style('upload-icon')} htmlFor="uploadInput">
          {uploadButton}
        </label>
        <div className={style('tip')}>建议宽高比为2:1, 图片不超过10M(png/jpg/jpeg)</div>
      </Form.Item>
      {/* {!isEdit && (
        <Form.Item label="是否为端侧项目" name="isTerminal">
          <Switch />
        </Form.Item>
      )} */}
    </Form>
  );
};

ProjectForm = forwardRef(ProjectForm as any);

export default ProjectForm;
