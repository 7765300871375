import Axios, { AxiosRequestConfig } from 'axios';
import cookie from 'react-cookies';
import { message } from '@fuxi/eevee-ui';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import {
  CrowdsourcingAccountBackendUrl,
  EditionKeyInHeader,
  EditionType,
  OriginType,
  OriginTypeKeyInHeader,
  SECRET,
  USER_NAME,
} from '@/constants/login';
import store from '../store';
import responseHandler, { shuyuanResponseHandler, accountResponseHandler } from './utils/responseHandler';
import errorHandler, { webInstanceErrorHandler } from './utils/errorHandler';
import { mlErrorHandler } from './utils/mlErrorHandler';
import { createServerTime } from './utils/serverTime';

export * from './utils/URLGroup';

const BasicInstance = Axios.create({
  baseURL: '/api/v1',
  timeout: 120000,
});

BasicInstance.interceptors.response.use(responseHandler, errorHandler);

BasicInstance.interceptors.request.use(config => {
  const token = cookie.load('RBAC_TOKEN');
  // @ts-ignore:next-line
  config.headers['X-Rbac-Token'] = token || '';
  return config;
});

const RbacInstance = Axios.create({
  baseURL: '/rbac/api',
  timeout: 60000,
});

RbacInstance.interceptors.response.use(responseHandler, errorHandler);

const CommonConfigInstance = Axios.create({
  baseURL: '/common-config',
  timeout: 60000,
});
CommonConfigInstance.interceptors.response.use(responseHandler, errorHandler);

// 后端新的请求地址
const WebInstance = Axios.create({
  baseURL: '/web/api/v1',
  timeout: 60000,
});

WebInstance.interceptors.response.use(responseHandler, webInstanceErrorHandler);

WebInstance.interceptors.request.use(config => {
  const token = cookie.load('RBAC_TOKEN');
  // @ts-ignore:next-line
  // TODO TS 类型完善
  config.headers['X-Rbac-Token'] = token || '';
  return config;
});

// ml服务请求, 以 /api/v1/ml 为前缀
const MlInstance = Axios.create({
  baseURL: '/ml/api/v1/ml',
  timeout: 60000,
});

MlInstance.interceptors.response.use(responseHandler, mlErrorHandler);

MlInstance.interceptors.request.use(config => {
  const token = cookie.load('RBAC_TOKEN');
  // @ts-ignore:next-line
  // TODO TS 类型完善
  config.headers['X-Rbac-Token'] = token || '';
  const danluProjectId = store.getState().project.currentMlProject.aiProjectId;
  if (!danluProjectId) {
    message.error('danlu project id is empty');
  } else {
    // @ts-ignore:next-line
    config.headers['Project_id'] = store.getState().project.currentMlProject.aiProjectId || '';
  }
  return config;
});

// cdn服务
const CdnInstance = Axios.create({
  baseURL: '/cdn/api/v1',
  timeout: 60000,
});

CdnInstance.interceptors.response.use(responseHandler, errorHandler);

// 模型服务日志
const ModelServiceLogInstance = Axios.create({
  baseURL: '/danlu/log/api/v1',
  timeout: 60000,
});
ModelServiceLogInstance.interceptors.response.use(responseHandler, errorHandler);

export {
  RbacInstance,
  CommonConfigInstance,
  WebInstance,
  CdnInstance,
  MlInstance,
  ModelServiceLogInstance,
  ShuyuanHistoryLogInstance,
};

export default BasicInstance;

// 数源历史日志
const ShuyuanHistoryLogInstance = Axios.create({
  baseURL: '/api/v1/historyLog',
  timeout: 30000,
});

ShuyuanHistoryLogInstance.interceptors.response.use(shuyuanResponseHandler, errorHandler);

export type GatewayConfigOptions = {
  secret: string;
  username: string;
};
const hmac = (config: AxiosRequestConfig, { secret, username }: GatewayConfigOptions) => {
  const { data = '', url, baseURL = '', method } = config;
  const body_digest = `SHA-256=${Base64.stringify(sha256(data))}`;
  const uri = baseURL + url;
  const date = createServerTime().time.toUTCString();
  const sign = `x-date: ${date}\n${method?.toUpperCase()} ${uri} HTTP/1.1`;
  const base64_digest = Base64.stringify(hmacSHA256(sign, secret!));
  const authorization = `hmac username="${username}", algorithm="hmac-sha256", headers="x-date request-line", signature="${base64_digest}"`;

  return {
    'X-Date': date,
    Digest: body_digest,
    Authorization: authorization,
  };
};

export const UserGatewayInstance = Axios.create({
  baseURL: CrowdsourcingAccountBackendUrl ? `${CrowdsourcingAccountBackendUrl}/api/user` : '/api/user',
  timeout: 30000,
});

UserGatewayInstance.interceptors.request.use(config => {
  if (CrowdsourcingAccountBackendUrl && SECRET && USER_NAME) {
    Object.assign(
      config.headers || {},
      hmac(config, {
        secret: SECRET!,
        username: USER_NAME!,
      })
    );
  }
  config.headers![EditionKeyInHeader] = EditionType.Personal;
  config.headers![OriginTypeKeyInHeader] = OriginType;
  return config;
});

UserGatewayInstance.interceptors.response.use(accountResponseHandler, errorHandler);

export const downloadInitTool = ({ projectId, serviceId }: { projectId: number; serviceId: string }) => {
  return BasicInstance.get(`/${projectId}/${serviceId}/edge/init/tool`, {
    responseType: 'blob',
  }).then(res => {
    console.log(res, res.data);
    const name = res.headers['content-disposition'].split('=')?.[1];
    let blob = new Blob([res.data], { type: 'application/octet-stream' });
    let url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
    URL.revokeObjectURL(url);
  });
};
