import { gql } from '@apollo/client';

export const TAG_FRAGMENT = gql`
  fragment TagFragment on Tag {
    tagId
    tagName
    creator
  }
`;

export const INDUSTRY_FRAGMENT = gql`
  fragment IndustryFragment on Industry {
    industryId
    industryName
    description
    want
    color
    icon
  }
`;

export const VERSION_INFO_FRAGMENT = gql`
  fragment VersionInfoFragment on Version {
    version
    description
    updateTime
  }
`;

export const TEMPLATE_FRAGMENT = gql`
  fragment TemplateFragment on Template {
    id
    name

    description
    config
    status
    creator
    updater
    createdTime
    updatedTime
    iconUrl
    tag
    tags {
      ...TagFragment
    }
    industry {
      ...IndustryFragment
    }
    versionInfo {
      ...VersionInfoFragment
    }
    useNum
    source
    contactInfo
    clientSample
  }
  ${TAG_FRAGMENT}
  ${INDUSTRY_FRAGMENT}
  ${VERSION_INFO_FRAGMENT}
`;

export const QUERY_TEMPLATES = gql`
  query QueryTemplates($input: QueryTemplateInput) {
    templates(input: $input) {
      ...TemplateFragment
    }
  }
  ${TEMPLATE_FRAGMENT}
`;

export const QUERY_TAGS = gql`
  query QueryTags {
    tags {
      ...TagFragment
    }
  }
  ${TAG_FRAGMENT}
`;

export const QUERY_INDUSTRY = gql`
  query QueryIndustry {
    industry {
      ...IndustryFragment
    }
  }
  ${INDUSTRY_FRAGMENT}
`;

export const VALIDATE_VERSION = gql`
  query ValidateVersion($templateId: ID!, $tag: String!) {
    validateTag(templateId: $templateId, tag: $tag)
  }
`;
