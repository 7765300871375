/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { random } from 'lodash-es';
import { useInterval } from '@fuxi/eevee-hooks';
import { Button, Card, Tooltip, Spin, Flex, Layout, StatusTag, Icon, Alert, Progress, message } from '@fuxi/eevee-ui';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { ProjectStatus } from '@/constants';
import { isProjectCreator } from '@/utils/getUserName';
import { useAppDispatch } from '@/hooks';
import { queryCommonConfigs } from '@/store/commonConfig';
import { setFeedbackModalNonce } from '@/store/user';
import { PublishVersionDrawer } from '@/components/PublishVersionDrawer';
import { ServiceType } from '@/typings/common';
import EditProject from '../EditProject';
import DeleteProject from '../DeleteProject';

import { openVisualWebsite } from '../const';
import { canPublishService } from '../utils';
import ProjectDetailContainer from './ProjectDetailContainer';
import PublishModal from './PublishModal';
import ProjectBreadcrumb from './ProjectBreadcrumb';
import ProjectSiderMenu from './ProjectSiderMenu';
import { serverStatusMap } from './constants';
import cx from './ProjectDetail.module.less';

let timeoutId = 0;

const ProjectDetail: React.FC = () => {
  const container = ProjectDetailContainer.useContainer();
  const {
    isTpl,
    projectType,
    currentProject,
    dispatchSetProject,
    getProjectUsers,
    userInfo,
    projectInfo,
    serviceList,
    fetchProjectInfo,
    fetchTerminalInfo,
    fetchImageInfo,
  } = container;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlParams = useParams();
  const projectId = urlParams?.currentProjectId;
  const [percent, setPercent] = useState(0);
  const [createLoading, setCreateLoading] = useState(false);

  // 当前版本只有一个主干
  const mainServer = serviceList.find(item => item.type === ServiceType.Main);

  // 判断当前url是否包含public-ability
  const isPublicAbility = window.location.href.includes('public-ability');

  const toFetchProjectInfo = () => {
    fetchProjectInfo({ variables: { projectId: projectId } });
    fetchImageInfo({ variables: { projectId: projectId } });
    fetchTerminalInfo({ variables: { projectId: projectId } });
    dispatch(queryCommonConfigs());
  };

  function showFeedbackModal() {
    dispatch(setFeedbackModalNonce(Date.now()));
  }

  useEffect(() => {
    projectInfo?.project && dispatchSetProject(projectInfo?.project);
  }, [projectInfo]);

  useEffect(() => {
    if (!projectId) {
      navigate('/project');
      return;
    }
    toFetchProjectInfo();
  }, []);

  useEffect(() => {
    if (currentProject.id) {
      getProjectUsers({ variables: { projectId: currentProject.id } });
    }
  }, [currentProject.id]);

  useEffect(() => {
    window.clearTimeout(timeoutId);

    if (currentProject.status === ProjectStatus.Creating) {
      timeoutId = window.setTimeout(() => {
        toFetchProjectInfo();
      }, 2000);
    }
  }, [currentProject]);

  useEffect(() => {
    if (currentProject.status === ProjectStatus.Success && projectId === `${currentProject.id}`) {
      setPercent(99);
      clearInterval();
      setTimeout(() => {
        createLoading && message.success('项目创建成功！');
        setCreateLoading(false);
      }, 500);
    }
  }, [currentProject]);

  useEffect(() => {
    if (currentProject.status === ProjectStatus.Creating) {
      createLoading || setCreateLoading(true);
    }
  }, [currentProject]);

  const clearInterval = useInterval(() => {
    const incr = random(0, 3, false);
    if (percent + incr >= 99) {
      setPercent(99);
      clearInterval();
    } else {
      setPercent(percent => percent + incr);
    }
  }, 1000);

  const { Content, Sider } = Layout;

  const getMainServiceStatus = () => {
    try {
      const status = serverStatusMap[mainServer?.status!].label;
      return status;
    } catch (error) {
      return '';
    }
  };

  return (
    <Spin
      style={{ height: '100%' }}
      wrapperClassName={cx('spin-wrapper')}
      spinning={currentProject?.status === ProjectStatus.Creating || createLoading}
      indicator={
        <Progress
          type="circle"
          strokeWidth={4}
          percent={percent}
          className={cx('create-project-progess')}
          format={percent => (
            <>
              {percent}
              <span style={{ fontSize: 14 }}>%</span>
            </>
          )}
        />
      }
      tip={<div className={cx('ant-create-project-spin-text')}>项目创建中，请稍后...</div>}>
      <Card className={cx('service-tabs')} style={{ height: '100%' }}>
        <Flex className={cx('project-name-wrapper')} alignCenter justifyBetween>
          <Flex className={cx('project-name')} alignCenter>
            {currentProject.name}
            {/* {projectType === 'OUTER' ? (
              <Alert
                message={
                  <div>
                    本项目属于众包数据标注类项目，暂不能使用 “AI智能体” 模块的相关功能，如有定制化AI相关需求，
                    <a href="javascript:;" onClick={showFeedbackModal}>
                      请填写您的需求
                      <EditOutlined />
                    </a>
                  </div>
                }
                type="info"
                showIcon
                style={{ marginLeft: 16 }}
              />
            ) : null} */}
            {isTpl && (
              <StatusTag
                style={{
                  display: 'flex',
                  background: '#E58616',
                  marginLeft: 12,
                  color: 'white',
                  fontSize: 14,
                  fontWeight: 400,
                }}
                color="warning">
                模板
              </StatusTag>
            )}
          </Flex>
          <Flex alignCenter>
            {isProjectCreator(currentProject.creator, userInfo.name) ? (
              <PublishModal
                disabled={!canPublishService(mainServer)}
                server={{
                  id: mainServer?.id!,
                  name: mainServer?.name!,
                }}>
                <Tooltip
                  title={
                    canPublishService(mainServer) || !mainServer?.status
                      ? ''
                      : `${mainServer?.name}在 ${getMainServiceStatus()} 状态下不能发布`
                  }>
                  <Button disabled={!canPublishService(mainServer)} className={cx('action-buttons')} type="default">
                    发布到
                  </Button>
                </Tooltip>
              </PublishModal>
            ) : (
              <Tooltip title="仅项目创建者有此权限">
                <Button className={cx('action-buttons')} type="default" disabled>
                  发布到
                </Button>
              </Tooltip>
            )}
            {currentProject.tag === 'MINI' ? (
              <></>
            ) : (
              <PublishVersionDrawer>
                <Button className={cx('action-buttons')} type="default">
                  查看发布版本
                </Button>
              </PublishVersionDrawer>
            )}

            <Button
              disabled={!currentProject.id}
              className={cx('action-buttons', 'dev-btn')}
              type="primary"
              onClick={() => openVisualWebsite(currentProject?.id)}>
              <Flex alignCenter>
                <Icon className={cx('dev-icon')} size={16} name="可视化开发-项目中心"></Icon>
                可视化开发
              </Flex>
            </Button>
          </Flex>
        </Flex>
        <Layout style={{ padding: '0 0' }}>
          <Sider width={208} className={cx('menu-sider')}>
            <ProjectSiderMenu mode="inline" />
          </Sider>
          <Content
            // 用于解决项目详情页的滚动条问题
            id="project-detail-content"
            style={{ minHeight: 280, overflowX: 'auto' }}
            className={isPublicAbility ? cx('public-ability-wrapper') : undefined}>
            <ProjectBreadcrumb />
            <Outlet />
          </Content>
        </Layout>
      </Card>
    </Spin>
  );
};

const ProjectDetailWrapper = () => {
  return (
    <ProjectDetailContainer.Provider>
      <ProjectDetail />
      <EditProject useContainer={ProjectDetailContainer.useContainer} />
      <DeleteProject useContainer={ProjectDetailContainer.useContainer} />
    </ProjectDetailContainer.Provider>
  );
};

export default ProjectDetailWrapper;
