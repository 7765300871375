import cookie from 'react-cookies';
import { NeteaseDomain, RbacTokenName, RbacUserName, YLNeteaseDomain } from '@/constants/global';
import { openNetworkErrorNotification } from './utils/openNotification';

export const youlingAuthenticator = async ({ httpStatus, code, message }) => {
  const url = window.location.href;

  if (url?.includes(RbacTokenName) && url?.includes(RbacUserName)) {
    // 提取host和path
    const host = window.location.host;
    const path = window.location.pathname;
    const next = `https://${host}${path}`;
    const params = new URLSearchParams(new URL(url).search);
    const token = params.get(RbacTokenName);
    const user = params.get(RbacUserName);
    cookie.save(RbacTokenName, token as string, { domain: NeteaseDomain, path: '/' });
    cookie.save(RbacUserName, user as string, { domain: NeteaseDomain, path: '/' });

    cookie.save(RbacTokenName, token as string, { domain: YLNeteaseDomain, path: '/' });
    cookie.save(RbacUserName, user as string, { domain: YLNeteaseDomain, path: '/' });

    next && (window.location.href = next);
  } else {
    if (httpStatus === 401 && window.location.pathname !== '/login') {
      openNetworkErrorNotification(message, code);
      // window.location.href = `/rbac/login?next=${window.location.href}`;
      window.location.href = `${window.location.origin}/login?next=${window.location.href}`;
    }
  }
};
