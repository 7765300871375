import React, { useState } from 'react';
import {
  Button,
  message,
  Popconfirm,
  Select,
  Space,
  Table,
  TableColumnsType,
  TablePaginationConfig,
} from '@fuxi/eevee-ui';
import { debounce, findIndex } from 'lodash-es';
import client from '@/service/graphql';
import { SEARCH_USER_BY_EMAIL } from '@/service/schema/service/service-queries';
import { isProjectCreator } from '@/utils/getUserName';
import { ServiceStatus } from '@/typings/common';
import ProjectDetailContainer from '../ProjectDetailContainer';

import { defaultCurrentPage, defaultPageSize } from '../../const';
import cx from './UserManage.module.less';

interface UserManageProps {
  editPermission: boolean;
}

export const UserManage: React.FC<UserManageProps> = props => {
  const { editPermission } = props;
  const container = ProjectDetailContainer.useContainer();
  const {
    projectType,
    currentProject,
    serviceListLoading,
    projectUsers,
    userInfo,
    addMemeberLoading,
    postUpdateUsers,
    getServicesData,
  } = container;
  const [email, setEmail] = useState<string>();
  const [matchedUserList, setMatchedUserList] = useState<User[]>([]);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: defaultPageSize,
    current: defaultCurrentPage,
  });

  const handleFetchUserList = (email: string) => {
    email && debouncedFetchUserList(email);
  };

  const toGetServicesData = () => {
    getServicesData({ variables: { projectId: currentProject.id } });
  };

  const debouncedFetchUserList = debounce((email: string) => {
    email &&
      client
        .query(SEARCH_USER_BY_EMAIL, { email, pageNo: 1, pageSize: 20 })
        .then((data: any) => data?.searchUserByEmail?.items)
        .then((userList?: User[]) => {
          setMatchedUserList(userList || []);
        });
  }, 500);

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const handleMemberDelete = (record: User) => {
    // 检查此用户对应的服务器是否有正在运行的
    const serviceList = currentProject.services;
    const serviceListByUser = serviceList?.filter(item => item.creator === record.email);
    const isRunning = serviceListByUser?.some(item => item.status === ServiceStatus.Running);
    if (isRunning) {
      message.warning('请先关闭此用户对应的测试服');
      return;
    }

    const userEmails = projectUsers.filter(item => item.email !== record.email).map(item => item.email);
    const payload = {
      projectId: currentProject.id,
      users: userEmails,
    };

    postUpdateUsers({ variables: { input: payload } })
      .then(() => {
        toGetServicesData();
      })
      .catch(e => {});
  };

  const handleMemberAdd = () => {
    if (projectType === 'OUTER' && projectUsers.length >= 2) {
      message.info('该项目成员数量已达上限');
      return;
    }

    if (!email) {
      message.info('邮箱格式错误');
      return;
    }
    if (findIndex(projectUsers, item => item.email === email) !== -1) {
      message.info('该成员已存在');
      return;
    }
    const userEmails = projectUsers.map(item => item.email);
    userEmails.push(email);
    const payload = {
      projectId: currentProject.id,
      users: userEmails,
    };
    postUpdateUsers({ variables: { input: payload } })
      .then(() => {
        message.success('添加人员成功');
        toGetServicesData();
      })
      .catch(e => {});
  };

  const isDeleteDisabled = (record: User) => {
    return (
      currentProject.creator !== userInfo.email ||
      isProjectCreator(currentProject.creator, record.name) ||
      !editPermission ||
      userInfo.email === record.email
    );
  };

  const columns: TableColumnsType<User> = [
    {
      title: '姓名',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: '邮箱',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: '操作',
      key: 'action',
      width: 150,
      render: (_, record) => (
        <Popconfirm
          title="是否删除成员及对应服务器？"
          disabled={
            isProjectCreator(currentProject.creator, record.name) || !editPermission || userInfo.email === record.email
          }
          // onOpenChange={e => {
          //   if (e && isDeleteDisabled(record)) {
          //     const serviceList = currentProject.services;
          //     const serviceListByUser = serviceList?.filter(item => item.creator === record.email);
          //     const isRunning = serviceListByUser?.some(item => item.status === ServiceStatus.Running);
          //     if (isRunning) {
          //       message.info('请先关闭此用户对应的测试服');
          //       return;
          //     }
          //   }

          //   // e.preventDefault();
          // }}
          onConfirm={() => handleMemberDelete(record)}
          okText="确定"
          cancelText="取消">
          <Button style={{ padding: 0 }} disabled={isDeleteDisabled(record)} danger type="link">
            <span className={isDeleteDisabled(record) ? undefined : cx('deleted-btn')}>删除</span>
          </Button>
        </Popconfirm>
      ),
    },
  ];
  return (
    <div className={cx('user-manage-wrapper')}>
      <div className={cx('title')}>权限管理</div>

      <Space>
        <Select
          disabled={currentProject.creator !== userInfo.email}
          value={email}
          onChange={handleEmailChange}
          onSearch={handleFetchUserList}
          placeholder={currentProject.creator === userInfo.email ? '请完整输入用户邮箱' : '仅有项目创建者能添加人员'}
          showArrow={false}
          notFoundContent={null}
          style={{ width: 300 }}
          defaultActiveFirstOption={false}
          filterOption={false}
          showSearch>
          {matchedUserList.map(item => (
            <Select.Option value={item.email} key={item.email + item.name + item.fullName}>
              {item.fullName} {item.email}
            </Select.Option>
          ))}
        </Select>
        <Button
          className={cx('add-button')}
          loading={addMemeberLoading}
          disabled={!editPermission}
          onClick={handleMemberAdd}
          type="primary">
          添加人员
        </Button>
      </Space>

      <Table
        rootClassName={cx('table-wrapper')}
        loading={serviceListLoading}
        rowKey="email"
        columns={columns}
        dataSource={projectUsers || []}
        pagination={{
          pageSize: pagination?.pageSize,
          current: pagination?.current,
          showTotal: () => `共 ${projectUsers?.length} 条`,
          showSizeChanger: true,
          defaultPageSize: defaultPageSize,
          pageSizeOptions: [8, 10, 20, 50, 100],
          onChange: (page, pageSize) => {
            setPagination({ current: page, pageSize: pageSize });
          },
          showQuickJumper: true,
        }}
      />
    </div>
  );
};
