import { UserGatewayInstance } from './common';

export type BackendResponse<T> = {
  data: T;
  status: number;
};

export enum LoginType {
  Phone = 1,
  Password = 2,
  Urs = 3,
  Inner = 4,
}

export type LoginResponse = {
  rbacToken: string;
  rbacUser: string;
};

export const account = {
  // 登录
  login(body: {
    code?: string;
    phone?: string;
    region?: string;
    type: LoginType;
  }): Promise<BackendResponse<LoginResponse>> {
    return UserGatewayInstance.post('/rbac/common/login', body);
  },
};
