import React, { useEffect } from 'react';
import { Divider, Flex, Icon } from '@fuxi/eevee-ui';
import Qs from 'query-string';

import { AOP_ASSISTANT_WINDOW_ID_KEY, AOP_ASSISTANT_SOURCE } from '@/constants/login';

import Mail from './components/Mail';
import cx from './index.module.less';

interface Props {}

export const Login: React.FC<Props> = props => {
  const parsedUrl = Qs.parseUrl(window.location.href);
  const { next, source, windowId } = parsedUrl?.query;

  useEffect(() => {
    if (source === AOP_ASSISTANT_SOURCE) {
      window.localStorage.setItem(AOP_ASSISTANT_WINDOW_ID_KEY, windowId as string);
    }
  }, []);

  return (
    <div className={cx('login')}>
      <Flex alignCenter justifyEnd className={cx('content-wrapper')}>
        <Flex className={cx('content')}>
          <Flex className={cx('detail')} gap={16} alignCenter justifyStart flexDirection="column">
            <div className={cx('title')}>欢迎来到有灵平台</div>
            <div className={cx('desc')}>请使用网易邮箱登录</div>

            <Mail />

            <Divider type="horizontal">
              <div style={{ color: 'rgb(1 3 22 / 85%)', fontWeight: 400 }}>更多方式</div>
            </Divider>

            <Flex alignCenter gapXS className="font-w-normal font-size-base color-text-normal">
              <Icon style={{ color: '#FF4141' }} name="openid" size={24} />
              <a
                className={cx('other-login')}
                href={`/rbac/login?next=${
                  source === AOP_ASSISTANT_SOURCE
                    ? `${window.location.origin}/aopAssistantLoginCallback`
                    : next || window.location.origin
                }`}>
                OpenID 登录
              </a>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};
export default Login;
