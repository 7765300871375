import { WebInstance } from "./common";

// 获取服务配置
export const getServiceConfig = (serviceId: number, projectId: number) => {
  return WebInstance.get(`/server/config`, {
    params: {
      serverId: serviceId,
      projectId,
    },
  });
};

// 更新服务配置
export const updateServiceConfig = (projectId: number, serviceId: number, config: {
  danluClusterId?: number;
  debugUrlTemplate?: string;
  pageUrlTemplate?: string;
  serverUrlTemplate?: string;
  shuyuanProperties?: {
    host?: string;
    username?: string;
    secret?: string;
    outerNetHost?: string;
    datasetEventUrl?: string;
  }
}) => {
  return WebInstance.post(`/server/config`, config, {
    params: {
      projectId,
      serverId: serviceId,
    },
  });
};