import { useEffect, useState } from 'react';
import { Flex } from '@fuxi/eevee-ui';
import { Button, Form, Input, InputNumber, message, Select, Spin } from 'antd';
import { useAppSelector } from '@/hooks';
import { getServiceConfig, updateServiceConfig } from '@/service/serviceConfig';
import { services } from '@/service/service';

export const ManageServiceConfig = () => {
  const [form] = Form.useForm();
  const { getServicesByProject } = services;
  const projectsList = useAppSelector(state => state.project.mlProjectList);
  const [selectedProjectId, setSelectedProjectId] = useState<number | undefined>();
  const [serviceId, setServiceId] = useState<number | undefined>();
  const [getDetailLoading, setGetDetailLoading] = useState(false);
  const [fetchServiceConfigLoading, setFetchServiceConfigLoading] = useState(false);
  const [currentServiceList, setCurrentServiceList] = useState<any[]>([]);

  const onReset = () => {
    if (!selectedProjectId || !serviceId) {
      form.resetFields();
      return;
    } else {
      setGetDetailLoading(true);
      getServiceConfig(serviceId, selectedProjectId)
        .then(res => {
          message.success('获取配置信息成功, 正在加载...');
          form.setFieldsValue(res.data);
          setTimeout(() => {
            form.setFieldsValue(res.data);
            setGetDetailLoading(false);
          }, 300);
        })
        .catch(err => {
          setGetDetailLoading(false);
        });
    }
  };

  const onSubmit = async () => {
    const value = await form.validateFields();
    if (!selectedProjectId || !serviceId) {
      return;
    }
    setGetDetailLoading(false);
    updateServiceConfig(selectedProjectId, serviceId, value).then(res => {
      message.success('更新成功');
      form.setFieldsValue(res.data);
    });
  };

  useEffect(() => {
    if (!selectedProjectId || !serviceId) {
      return;
    }
    setGetDetailLoading(true);
    getServiceConfig(serviceId, selectedProjectId)
      .then(res => {
        message.success('获取配置信息成功, 正在加载...');
        form.setFieldsValue(res.data);
        setTimeout(() => {
          form.setFieldsValue(res.data);
          setGetDetailLoading(false);
        }, 300);
      })
      .catch(err => {
        setGetDetailLoading(false);
        form.resetFields([
          'danluClusterId',
          'serverUrlTemplate',
          'debugUrlTemplate',
          'pageUrlTemplate',
          ['shuyuanProperties', 'host'],
          ['shuyuanProperties', 'username'],
          ['shuyuanProperties', 'secret'],
          ['shuyuanProperties', 'outerNetHost'],
          ['shuyuanProperties', 'datasetEventUrl'],
        ]);
      });
  }, [serviceId]);

  useEffect(() => {
    if (!selectedProjectId) {
      form.resetFields();
      return;
    }

    setFetchServiceConfigLoading(true);
    getServicesByProject(selectedProjectId)
      .then(res => {
        setCurrentServiceList(res.services.items);
      })
      .finally(() => {
        setFetchServiceConfigLoading(false);
      });
  }, [selectedProjectId]);

  return (
    <Spin spinning={getDetailLoading}>
      <Flex justifyCenter alignCenter flexDirection="column">
        <Form
          labelCol={{ span: 6 }}
          style={{
            width: '700px',
          }}
          form={form}>
          <Form.Item label="项目" name="projectId" rules={[{ required: true }]}>
            <Select
              loading={fetchServiceConfigLoading}
              placeholder="请先选择项目及配置服务器ID"
              // searchValue="projectId"
              options={projectsList.map(item => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })}
              onChange={value => {
                setSelectedProjectId(value);

                form.resetFields([
                  'serviceId',
                  'danluClusterId',
                  'serverUrlTemplate',
                  'debugUrlTemplate',
                  'pageUrlTemplate',
                  ['shuyuanProperties', 'host'],
                  ['shuyuanProperties', 'username'],
                  ['shuyuanProperties', 'secret'],
                  ['shuyuanProperties', 'outerNetHost'],
                  ['shuyuanProperties', 'datasetEventUrl'],
                ]);
              }}
            />
          </Form.Item>

          <Form.Item required label="服务器ID" name="serviceId" rules={[{ required: true, message: '请输入服务器ID' }]}>
            <Select
              loading={fetchServiceConfigLoading}
              placeholder="请先选择项目及配置服务器ID"
              // searchValue="projectId"
              options={currentServiceList.map(item => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })}
              onChange={value => {
                setServiceId(value);
              }}
            />
          </Form.Item>

          <Form.Item label="丹炉Cluster Id" name="danluClusterId">
            <InputNumber
              style={{
                width: '100%',
              }}
              placeholder="(选填)"
              disabled={!selectedProjectId || !serviceId}></InputNumber>
          </Form.Item>

          <Form.Item label="服务器地址" name="serverUrlTemplate">
            <Input placeholder="(选填)" disabled={!selectedProjectId || !serviceId}></Input>
          </Form.Item>

          <Form.Item label="调试地址" name="debugUrlTemplate">
            <Input placeholder="(选填)" disabled={!selectedProjectId || !serviceId}></Input>
          </Form.Item>

          <Form.Item label="页面地址" name="pageUrlTemplate">
            <Input placeholder="(选填)" disabled={!selectedProjectId || !serviceId}></Input>
          </Form.Item>

          <div
            style={{
              width: '100%',
              padding: '20px 20px 0px 20px',
              border: '1px dashed #767676',
              borderRadius: '4px',
            }}>
            <div
              style={{
                paddingLeft: '12px',
              }}>
              数源配置
            </div>
            <Form.Item label="host" name={['shuyuanProperties', 'host']}>
              <Input placeholder="(选填)" autoComplete="off" disabled={!selectedProjectId || !serviceId}></Input>
            </Form.Item>

            <Form.Item label="username" name={['shuyuanProperties', 'username']}>
              <Input placeholder="(选填)" autoComplete="off" disabled={!selectedProjectId || !serviceId}></Input>
            </Form.Item>

            <Form.Item label="secret" name={['shuyuanProperties', 'secret']}>
              <Input placeholder="(选填)" autoComplete="off" disabled={!selectedProjectId || !serviceId}></Input>
            </Form.Item>

            <Form.Item label="outerNetHost" name={['shuyuanProperties', 'outerNetHost']}>
              <Input placeholder="(选填)" autoComplete="off" disabled={!selectedProjectId || !serviceId}></Input>
            </Form.Item>

            <Form.Item label="datasetEventUrl" name={['shuyuanProperties', 'datasetEventUrl']}>
              <Input placeholder="(选填)" autoComplete="off" disabled={!selectedProjectId || !serviceId}></Input>
            </Form.Item>
          </div>
        </Form>

        <Flex alignCenter gapLG style={{ marginTop: '20px' }}>
          <Button onClick={onReset}>重置</Button>

          <Button type="primary" onClick={onSubmit}>
            提交
          </Button>
        </Flex>
      </Flex>
    </Spin>
  );
};
