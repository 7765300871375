import React, { useEffect } from 'react';
import { account, LoginType } from '@/service/account';
import { useAccount } from '../../hooks/useAccount';
import { AgreementList } from '../../constants';

const Mail: React.FC = () => {
  const { login } = account;
  const { loginCb } = useAccount();
  const host = window.location.origin;
  async function handleLogin(_username: string) {
    const res = await login({
      type: LoginType.Urs,
    });
    loginCb(res.data);
  }
  function init() {
    try {
      const ele = document.getElementById('urs-login');
      if (ele?.childElementCount) {
        ele.innerHTML = '';
      }
      new URS({
        newCDN: 1, // 必须，代表使用的CDN是连通性更好的SNI域名
        version: 4, // 必须，代表版本4
        ...(!/.163.com$/.test(window.location.origin) && {
          cookieDomain: 'netease.com', // 产品页面地址非163.com域名的必填，可填入的域名列表：https://urs.hz.netease.com/docDetail.html?pid=384#/
          regCookieDomain: 'netease.com', // 产品页面地址非163.com域名的必填，可填入的域名列表：https://urs.hz.netease.com/docDetail.html?pid=384#/
        }),
        cssDomain: `${host}/`,
        cssFiles: 'login.css',
        includeBox: 'urs-login', // 必须，登陆框的外层节点的id
        product: 'fuxizhongbao', // 必填，申请的产品名，对应工单生成的配置product
        promark: 'wPyZSiF', // 必填，申请的组件ID，被分派ID，对应工单生成的配置promark
        host, // 必填，产品域名，对应工单生成的配置host
        page: 'login', // 可选，首屏显示登录，可选register
        single: 1, // 可选，只接入登录或注册模块
        isHttps: 1,
        goEmailLoginTxt: '登录',
        placeholder: { account: '请输入邮箱地址', pwd: '请输入邮箱密码' },
        domainSuffixs: '@163.com,@126.com,@yeah.net,@188.com,@vip.163.com,@vip.126.com',
        regDomainSuffixs: '@163.com',
        setMailloginClause: 1,
        gotoRegText: '立即注册网易邮箱',
        forgetPwdText: '忘记邮箱密码？',
        mailloginclause: {
          list: AgreementList,
        },
        agreeText: '我已阅读并同意',
        andText: '和',
        logincb: handleLogin,
      });
    } catch {}
  }
  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <div className="h-332px w-420px" id="urs-login"></div>
    </div>
  );
};

export default Mail;
