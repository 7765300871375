import { message } from '@fuxi/eevee-ui';
import { get } from 'lodash';

// 不需要全局提示的错误
// const noGlobalPromptCodes: number[] = [];

export const mlErrorHandler = (error: any) => {
  // Ignore error when request is canceled by user:
  const isCanceled = !(error instanceof Error) && error && typeof error.message === 'string';

  if (isCanceled) return;

  const status: number = get(error, 'response.status', 0);

  // 处理401
  if (status === 401) {
    // window.location.href = `/rbac/login?next=${window.location.href}`;
    window.location.href = `${window.location.origin}/login?next=${window.location.href}`;
  } else {
    const msg = get(error, 'response.data.message', '服务端异常');
    message.open({
      key: 'error',
      type: 'error',
      content: msg,
    });
  }

  return Promise.reject(error);
};
