class ServerTime {
  private _time = new Date();
  private _timeDiff: number = 0;

  constructor() {
    this.syncTime();
  }

  static create(): ServerTime {
    return new ServerTime();
  }

  syncTime() {
    fetch(window.location.href.toString(), { method: 'HEAD' })
      .then(response => {
        const dateString = response?.headers?.get('Date');
        if (dateString) {
          const localTime = new Date();
          const serverTime = new Date(dateString);
          this._time = serverTime;
          this._timeDiff = serverTime.getTime() - localTime.getTime();
        }
      })
      .catch(() => {
        console.error('Failed to sync time.');
      });
  }

  get time() {
    return new Date(Date.now() + this._timeDiff);
  }
}

const createServerTime = (function () {
  let instance: ServerTime;
  return function () {
    if (instance) return instance;
    return (instance = ServerTime.create());
  };
})();

const serverTime = createServerTime();

export { createServerTime, serverTime };
