import React from 'react';
import {
  Button,
  Flex,
  Row,
  Col,
  StatusTag,
  MenuProps,
  Dropdown,
  Space,
  Pagination,
  TablePaginationConfig,
  Typography,
  Tooltip,
} from '@fuxi/eevee-ui';
import { slice } from 'lodash';
import { ServerType } from '@/constants';
import IconFont from '@/components/IconFont';
import { PublishVersionDrawer } from '@/components/PublishVersionDrawer';
import { sliceByPagination } from '@/utils/sliceByPagination';
import { getRepoPullCommand } from '@/utils';
import ServiceDrawer from './ServiceDrawer';
import PublishModal from './PublishModal';
import ProjectDetailContainer from './ProjectDetailContainer';
import { useServiceOperation } from './useServiceOperation';
import { ServiceStatusOperation } from './ServiceStatusOperation';
import { serverStatusMap } from './constants';
import { PersonalPublishModal } from './PersonalPublishModal';
import cx from './ServiceList.module.less';

const { Text } = Typography;

const colLayout = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 12,
  xxl: 8,
};

const ActionButton = ({ record, projectId }: { record: Service; editPermission: boolean; projectId: number }) => {
  const { id, name, type } = record;
  const { isProjectCreator, isServiceCreator, canPublishService, canPublishPersonalService } =
    useServiceOperation(record);

  const btnStyle = { padding: '4px 12px' };

  const items: MenuProps['items'] = [
    {
      key: 'log',
      label: <ServiceDrawer service={record}>日志</ServiceDrawer>,
    },
    // {
    //   key: 'sdk',
    //   label: (
    //     <DownloadModal isPersonal={record.type === ServerType.Personal} serviceId={id} serviceName={name}>
    //       下载客户端SDK
    //     </DownloadModal>
    //   ),
    // },
    {
      key: 'serviceVersions',
      label: <PublishVersionDrawer serviceId={record.id}>查看发布版本</PublishVersionDrawer>,
    },
  ];

  return (
    <Flex justifyCenter alignCenter>
      {/* {isProjectCreator &&
        record.type === ServerType.Personal &&
        (canStartPersonalService ? (
          <Button
            style={btnStyle}
            disabled={canRestoreService}
            loading={restoringServer || enablingServer}
            onClick={handleEnableService}>
            启用
          </Button>
        ) : (
          <Button
            style={btnStyle}
            loading={restoringServer || disablingServer}
            disabled={!canDisablePersonalService || restoringServer || disablingServer}
            onClick={handleDisableService}>
            禁用
          </Button>
        ))} */}

      {type === ServerType.Main && (
        <PublishModal
          server={{ id, name }}
          disabled={!canPublishService || !isProjectCreator}
          children={
            <Button type="primary" style={btnStyle} disabled={!canPublishService}>
              发布
            </Button>
          }></PublishModal>
      )}

      {/*  私服对应人员及创建者可以发布  */}
      {type === ServerType.Personal && (
        <PersonalPublishModal
          isServiceCreator={isServiceCreator}
          isProjectCreator={isProjectCreator}
          projectId={projectId}
          serverInfo={{
            id: id,
            modules: record.modules || [],
          }}>
          <Button disabled={!canPublishPersonalService} type="primary" style={btnStyle}>
            发布
          </Button>
        </PersonalPublishModal>
      )}

      <Dropdown
        overlayStyle={{ width: 140 }}
        menu={{ items }}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}>
        <Space style={{ marginLeft: 8 }}>
          <IconFont className={cx('more-icon')} type="icon-gengduo" />
        </Space>
      </Dropdown>
    </Flex>
  );
};

interface ServiceListProps {
  editPermission: boolean;
  pagination: TablePaginationConfig;
  handlePaginationChange: (page: number, pageSize?: number) => void;
}

const ServiceCardList: React.FC<ServiceListProps> = props => {
  const { editPermission, pagination, handlePaginationChange } = props;
  const container = ProjectDetailContainer.useContainer();
  const {
    currentProject: { clientRepoGitUrl, customRepoGitUrl, serviceRepoGitUrl, id: projectId },
    serviceList,
  } = container;

  const getRepoServerPullCommand = (item: Service) => {
    return getRepoPullCommand({
      clientRepoGitUrl,
      customRepoGitUrl,
      serviceRepoGitUrl,
      serviceType: item?.type,
      version: item.version,
      creator: item.creator,
    })?.[2];
  };

  return (
    <div className={cx('card-wrapper')}>
      <Row gutter={[20, 20]}>
        {slice(serviceList, sliceByPagination(pagination).start, sliceByPagination(pagination).end).map(item => {
          const isMain = item.type === ServerType.Main;
          return (
            <Col key={item.id} {...colLayout}>
              <div className={cx('server-card')}>
                <ServiceDrawer service={item}>
                  <Flex justifyBetween>
                    <Flex alignCenter style={{ cursor: 'pointer', width: '100%' }} gap={12}>
                      <Flex.Item
                        flexShrink={0}
                        className={cx('server-icon', { 'main-server-icon': item.type === ServerType.Main })}>
                        {isMain ? '正式' : item.creator[0].toUpperCase()}
                      </Flex.Item>
                      <Flex
                        style={{
                          overflow: 'hidden',
                        }}
                        flexDirection="column"
                        gap={8}>
                        <Flex className={cx('server-branch')} alignCenter justifyBetween>
                          <div className={cx('git-command')}>
                            <Tooltip title={item.name} placement="topLeft">
                              {item.name}
                            </Tooltip>
                            &gt;
                          </div>
                          <Flex.Item flex={0}>
                            <StatusTag
                              className={cx('card-status-tag')}
                              style={{ marginRight: 0, color: serverStatusMap[item.status]?.color }}
                              color={serverStatusMap[item.status]?.color}>
                              {serverStatusMap[item.status]?.label}
                            </StatusTag>
                          </Flex.Item>
                        </Flex>
                        <div className={cx('server-branch')}>
                          server_id：<Text copyable={{ tooltips: false, text: item.id }}>{item.id}</Text>
                        </div>
                        <div className={cx('server-branch')}>
                          所属分支：{isMain ? '主分支' : `${item.creator.split('@')[0]} 的个人分支`}
                        </div>
                        {!isMain && (
                          <Flex className={cx('server-branch')} alignCenter>
                            ssh 连接命令：
                            {!item.sshUrl ? (
                              '-'
                            ) : (
                              <Text
                                className={cx('git-command')}
                                ellipsis={{ tooltip: item.sshUrl }}
                                copyable={{ tooltips: false, text: item.sshUrl }}>
                                {item.sshUrl}
                              </Text>
                            )}
                          </Flex>
                        )}
                        <Flex className={cx('server-branch')} alignCenter>
                          代码库拉取命令：
                          <Text
                            className={cx('git-command')}
                            ellipsis={{ tooltip: getRepoServerPullCommand(item) }}
                            copyable={{ tooltips: false, text: getRepoServerPullCommand(item) }}>
                            {getRepoServerPullCommand(item)}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </ServiceDrawer>
                <div className={cx('action-btn-wrapper')}>
                  <Flex alignCenter gap={8}>
                    <ServiceStatusOperation inCard editPermission={editPermission} record={item} />
                    <ActionButton projectId={projectId} record={item} editPermission={editPermission}></ActionButton>
                  </Flex>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      <Pagination
        className={cx('pagination')}
        showTotal={total => `共 ${total} 条`}
        pageSizeOptions={[5, 9, 10, 20, 50, 100]}
        showSizeChanger
        onChange={handlePaginationChange}
        pageSize={pagination.pageSize}
        defaultPageSize={pagination.defaultPageSize}
        current={pagination.current}
        showQuickJumper
        total={serviceList?.length}></Pagination>
    </div>
  );
};

export default ServiceCardList;
