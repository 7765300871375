// ⚠️如果项目需要兼容ie11，则需要引入下面两行polyfill
// These must be the first lines in src/index.tsx
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import 'antd/dist/reset.css';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// graphql
import { ApolloProvider } from '@apollo/client';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigIconProvider, ConfigProvider } from '@fuxi/eevee-ui';

import 'dayjs/locale/zh-cn';

import dayjs from 'dayjs';
import store from '@/store';
import routerConfig from '@/router/config';

import client from '@/service/graphql';
import { commonTheme } from '@/styles/commonTheme';

import './styles/index.less';
import { RbacInfo } from './pages/rbac';
// import { theme } from '@antv/g2plot/lib/adaptor/common';

const eeveeIconCdnLink = 'https://static.fuxi.netease.com/eevee-icon/resource/didaima-qianduan/TEIH1z14bwKvhRb.json';

const { algorithm = undefined, token = {}, components = {} } = commonTheme;

const queryClient = new QueryClient();

dayjs.locale('zh-cn');

ReactDOM.render(
  <ConfigProvider
    locale={zhCN}
    theme={{
      algorithm,
      token: { ...token },
      components: {
        ...components,
        Modal: {
          wireframe: true,
        },
      },
    }}>
    <ApolloProvider client={client.client}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <RbacInfo />
          <ConfigIconProvider cdnLink={eeveeIconCdnLink} defaultSize={16}>
            <RouterProvider router={createBrowserRouter(routerConfig)} />
          </ConfigIconProvider>
        </Provider>
      </QueryClientProvider>
    </ApolloProvider>
  </ConfigProvider>,
  document.getElementById('root')
);
